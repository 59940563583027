import { createI18n } from "vue-i18n";
import messages from './messages';

// Set and expose the default locale
export const defaultLocale = localStorage.getItem('currentLanguage') || 'en';

// Private instance of VueI18n object
let _i18n;

// Initializer
function setup(options = { locale: defaultLocale }) {
  _i18n = createI18n({
    locale: options.locale,
    fallbackLocale: 'en',
    messages,
  });
  setLocale(options.locale);
  return _i18n;
}

// Sets the active locale.
function setLocale(newLocale) {
  _i18n.global.locale = newLocale;
}

export const supportedLocales = {
  'en': 'EN',
  'mk': 'MK',
}

// Public interface
export default {
  // Expose the VueI18n instance via a getter
  get vueI18n() {
    return _i18n;
  },
  setup,
  setLocale,
};

<template>
  <article class="post-item">
    <localized-link :to="`${postTypeSlug}/${post.slug}`">
      <div class="post-image">
        <img 
          :src="post['_embedded']['wp:featuredmedia'][0].media_details.sizes['post-featured'].source_url" 
          :alt="post.title['rendered']">
      </div>
      <div class="post-info">
        <div class="post-title">
          <h5 v-if="getYear && getYear.length" class="year">{{ getYear[0].name }}</h5>
          <h4>{{ post.title['rendered'] }}</h4>
          <div v-if="post.excerpt['rendered']" v-html="post.excerpt['rendered']"></div>
        </div>
        <div class="post-link">
          <button-component 
            :html="'button'"
            :type="'arrow'">
          </button-component>
        </div>
      </div>
    </localized-link>
  </article>
</template>

<script>
  import ButtonComponent from '../button/ButtonComponent.vue';
  import LocalizedLink from '@/components/l10n/LocalizedLink.vue';
  export default {
    data() {
      return {
        groupId: null
      }
    },
    name: "PostItem",
    components: {
      ButtonComponent,
      LocalizedLink
    },
    props: {
      post: {
        type: Object,
        required: true
      },
      postTypeSlug: {
        type: String,
        requred: true,
      }
    },
    computed: {
      getYear(){
        const years = this.$store.getters['postTypeModule/getYears'];
        return years.filter((i) => {
          if(this.post['years']){
            return i.id === this.post['years'][0];
          }
          return [];
        });
      }
    }
  };
</script>
<style scoped>
  .post-item .post-image {
    margin-bottom: 20px;
    position: relative;
    overflow: hidden;
  }
  .post-item .post-image img {
    transition: all 1.2s ease-in-out;
  }
  .post-item:hover .post-image img {
    transform: scale(1.08);
  }
  .post-item .post-info {
    display: flex;
    gap: 30px;
    justify-content: space-between;
    align-items: flex-start;
  }
  .post-item .post-info .post-title {
    transition: .3s ease-in-out;
  }
  .post-item:hover .post-info .post-title {
    color: var(--gold);
  }
  .post-item .post-info .post-title .year {
    margin-bottom: 5px;
  }
</style>
<style>
  
  .post-item:hover .btn .icon {
    transform: rotate(0);
  }
  .post-item:hover .btn-arrow .icon {
    background-color: var(--gold);
  }
  .post-item:hover .btn-arrow .icon:before {
    opacity: 1;
  }
</style>
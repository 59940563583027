<template>
  <div class="exhibitions">
    <Transition name="loading-fade">
      <div v-if="loading" id="loading">
        <div class="loader"></div>
      </div>
    </Transition>
    <template v-if="!loading">
      <hero-section
        :title="getPageTitle"
        :text="pageContent.acf.hero_text || null"
        :image="pageContent.acf.hero_image['url'] || null" />
      <section class="post-listing-section">
        <div class="container">
          <filter-buttons
            :data="getAllCategories"
            :postType="'exhibitions'"
            @filterRequest="getCategoryPosts"/>
          <post-listing
            :data="getExhibitions"
            :postTypeSlug="'exhibitions-and-publications'"
            :catSlugs="getCatSlugs"/>
          <pagination-component
            :totalNumberItems="getInitialNumberOfExhibitions"
            :perPage="perPage" />
        </div>
      </section>
      <banner-section
        :title="pageContent.acf.banner_title"
        :text="pageContent.acf.banner_text"
        :image="pageContent.acf.banner_image['url']"
        :button="{
          link: 'artworks',
          text: $t('seeAll')
        }"/>
    </template>
  </div>
</template>

<script>
  import HeroSection from '@/components/global/HeroSection.vue';
  import BannerSection from '@/components/global/BannerSection.vue';
  import FilterButtons from '@/components/global/filters/FilterButtons.vue';
  import PostListing from '@/components/global/post-listing/PostListing.vue';
  import PaginationComponent from '@/components/global/pagination/PaginationComponent.vue';

  export default {
    data() {
      return {
        perPage: 12,
      }
    },
    name: "ExhibitionsView",
    components: {
      HeroSection,
      BannerSection,
      FilterButtons,
      PostListing,
      PaginationComponent
    },
    computed: {
      pageContent() {
        return this.$store.getters['exhibitionsPageModule/getPageContent'];
      },
      getPageTitle() {
        return (this.pageContent.acf.hero_title && this.pageContent.acf.hero_title !== '') ? this.pageContent.acf.hero_title : this.pageContent.title.rendered;
      },
      getExhibitions() {
        const exhibitions = this.$store.getters['postTypeModule/getExhibitions'];
        return exhibitions;
      },
      getInitialNumberOfExhibitions() {
        const exhibitions = this.$store.getters['postTypeModule/getInitialNumberOfExhibitions'];
        return exhibitions;
      },
      getAllCategories() {
        const categories = this.$store.getters['postTypeModule/getExhibitionsCategories'];        
        return categories;
      },
      getCatSlugs(){
        const catSlugs = this.getAllCategories;
        let mappedCats = {};
        catSlugs.map((c) => {
          mappedCats[c.id] = c.slug;
          return;
        });
        return mappedCats;
      },
      loading() {
        return this.$store.getters['exhibitionsPageModule/getLoading'];
      },
    },
    methods: {
      getPageContent() {
        this.$store.dispatch('exhibitionsPageModule/getPageContent');
      },
      getPostTypes(postType = 'exhibitions', perPage = null, pageNumber = null, categories = [], filterBySlug = false, postTypeFilter = [], filterByPostType = false, getInitial = false) {
        this.$store.dispatch('postTypeModule/getPostType', {
          postType,
          perPage,
          pageNumber,
          categories,
          filterBySlug,
          postTypeFilter,
          filterByPostType,
          getInitial
        });
      },
      getCategories(postType = 'exhibitions', taxonomy = null){
        this.$store.dispatch('postTypeModule/getCategories', {
          postType,
          taxonomy
        });
      },
      getCategoryPosts({postType, btnId}){
        const catId = btnId ? [btnId] : [];
        let pageQ = 1;
        if(Object.keys(this.$route.query).length > 0) {
          if(this.$route.query['page']){
            pageQ = this.$route.query['page'];
          }
        }
        if(Object.keys(this.$route.query).length == 1 && this.$route.query['page']){
          this.getPostTypes(postType, this.perPage, pageQ, catId, false, [], false, false);
        } else {
          this.getPostTypes(postType, this.perPage, pageQ, catId, false, [], false, true);
        }
        
      },
    },
    created(){
      this.getPageContent();
      this.getCategories('exhibitions', null);
      
      if(Object.keys(this.$route.query).length > 0) {
        let pageQ = 1;
        let cat = [];
        if(this.$route.query['page']){
          pageQ = this.$route.query['page'];
        }
        if(this.$route.query['cat']){
          cat.push(this.$route.query['cat']);
        }

        // const checkingArr = ['phases', 'materials', 'years'];
        // let catParams = Object.keys(this.$route.query).map(key => {
        //   if(!checkingArr.includes(key)){
        //     return null;
        //   }
        //   let q = key+"="+this.$route.query[key];
        //   return q;
        // });
        // catParams = catParams.filter(i => i !== null);
        // catParams = catParams.length > 0 ? catParams.join('&') : null;
        // const ptParams = catParams && catParams[0] === null ? null : catParams;
        
        if(Object.keys(this.$route.query).length == 1 && this.$route.query['page']){
          this.getPostTypes('exhibitions', this.perPage, pageQ, cat, false, [], false, false);
        } else {
          this.getPostTypes('exhibitions', this.perPage, pageQ, cat, false, [], false, true);
        }
      } else {
        this.getPostTypes('exhibitions', this.perPage, null, [], false, null, false, true);
      }
    },
    watch: {
      "$route.query": function(newVal, oldVal) {
        if(newVal !== oldVal){
          if(Object.keys(newVal).length > 0) {
            let pageQ = 1;
            let cat = [];
            if(newVal['page']){
              pageQ = newVal['page'];
            }
            if(newVal['cat']){
              cat.push(newVal['cat']);
            }

            // const checkingArr = ['phases', 'materials', 'years'];
            // let catParams = Object.keys(this.$route.query).map(key => {
            //   if(!checkingArr.includes(key)){
            //     return null;
            //   }
            //   let q = key+"="+this.$route.query[key];
            //   return q;
            // });
            // catParams = catParams.filter(i => i !== null);
            // catParams = catParams.length > 0 ? catParams.join('&') : null;
            // const ptParams = catParams && catParams[0] === null ? null : catParams;
            
            if(Object.keys(newVal).length == 1 && newVal['page']){
              this.getPostTypes('exhibitions', this.perPage, pageQ, cat, false, [], false, false);
            } else {
              this.getPostTypes('exhibitions', this.perPage, pageQ, cat, false, [], false, true);
            }
          } else {
            this.getPostTypes('exhibitions', this.perPage, null, [], false, null, false, true);
          }
        }
      }
    }
  };
</script>
<style scoped>
  .post-listing-section .filters {
    margin-bottom: 50px;
  }
  .post-listing-section .pagination {
    margin-top: 50px;
  }
</style>

<template>
  <section class="banner-section" :style="image ? `background-image: url(${image})` : null">
    <div class="container">
      <div class="banner-content">
        <h2 v-if="title">{{ title }}</h2>
        <div class="banner-text" v-if="text">
          <p>{{ text }}</p>
        </div>
        <button-component v-if="button" :link="button.link">
          {{ button.text }}
        </button-component>
      </div>
    </div>
  </section>
</template>

<script>
	import ButtonComponent from './button/ButtonComponent.vue';

  export default {
    name: "BannerSection",
		props: {
			title: {
				type: String,
				default: ''
			},
			text: {
				type: String,
				default: ''
			},
			image: {
				type: [Object, String],
				default: () => {}
			},
			button: {
				type: Object,
				default: () => {}
			}
		},
		components: {
			ButtonComponent,
    },
	};
</script>

<style scoped>
  .banner-section {
    min-height: 500px;
    display: flex;
    align-items: center;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    padding: 80px 0;
  }
  .banner-section:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(19, 19, 19, 0.3);
  }
  .banner-section .banner-content {
    text-align: center;
    position: relative;
    z-index: 1;
    color: #fff;
  }
  .banner-section .banner-content h2 {
    text-transform: uppercase
  }
  .banner-section .banner-content .banner-text {
    max-width: 650px;
    margin: 0 auto;
  }
  .banner-section .banner-content .banner-text:not(:last-child) {
    margin-bottom: 40px;
  }
</style>
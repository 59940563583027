<template>
  <div class="post-listing">
    <template v-if="data.length > 0">
      <post-item
        v-for="(post, i) in data"
        :key="i"
        :postTypeSlug="getCatSlug(post) !== '' ? `${postTypeSlug}/${getCatSlug(post)}` : postTypeSlug"
        :post="post"/>
    </template>
    <div v-else>
      <p><b>{{ $t('noPostsFound') }}</b></p>
    </div>
  </div>
</template>

<script>
  import PostItem from './PostItem.vue';
  export default {
    name: 'PostListing',
    components: {
      PostItem,
    },
    props: {
      data: {
        type: [Object, Array],
        required: true,
      },
      postTypeSlug: {
        type: String,
        required: true,
      },
      catSlugs: {
        type: Object,
        default: () => {}
      },
      emptyMessage: {
        type: String,
        default: 'No posts found'
      }
    },
    computed: {
      returnCatSlug(){
        return this.catSlugs || {};
      }
    },
    methods: {
      getCatSlug(post){
        if(Object.keys(this.returnCatSlug).length === 0){
          return '';
        }
        const cat = this.postTypeSlug === 'artworks' ? 'artwork-categories' : 'exhibition-publication-categories';
        return this.returnCatSlug[post[cat]];
      }
    }
  }
</script>

<style scoped>
  .post-listing {
    --gap: 40px;
    --columns: 3;
    display: flex;
    flex-wrap: wrap;
    gap: var(--gap);
  }
  .post-listing >>> .post-item {
    flex: 0 0 calc((100% / var(--columns)) - var(--gap) + (var(--gap) / var(--columns)));
    width: calc((100% / var(--columns)) - var(--gap) + (var(--gap) / var(--columns)));
  }
  @media (max-width: 991px) {
    .post-listing {
      --gap: 20px;
      --columns: 2;
    }
    .post-listing >>> .post-item {
      margin-bottom: 20px;
    }
  }
  @media (max-width: 767px) {
    .post-listing {
      --columns: 1;
    }
  }
</style>
import requestService from "@/services/request-service";
import { API_PATHS } from '@/services/api/config';
import { defaultLocale } from "@/plugins/i18n";

export const artworksPageModule = {
  namespaced: true,
  state: {
    pageContent: {},
    loading: false,
    pageID: defaultLocale === 'en' ? 9 : 118,
  },
  mutations: {
    updatePageContent(state, data){
      if(!data) {
        state.pageContent = {};
        return;
      }
      state.pageContent = data;
    },
    updateLoadingStatus(state, flag) {
      state.loading = flag;
    }
  },
  actions: {
    getPageContent({ commit, state }){
      commit('updateLoadingStatus', true);

      // //If we have cached data update the store with the cached data
      // let cachedData = sessionStorage.getItem('artworksCachedData');
      // if(cachedData){
      //   const parsedData = JSON.parse(cachedData);
      //   const dateNow = Date.now();
      //   const compareTime = 1000 * 60 * 5;
      //   if(Math.abs(parsedData.timestamp - dateNow) < compareTime) {
      //     commit('updatePageContent', parsedData.data);
      //     commit('updateLoadingStatus', false);
      //     return;
      //   }
      // }

      let API_URL = `${API_PATHS.pages}/${state.pageID}${defaultLocale === 'mk' ? `?lang=mk` : ''}`;
      requestService(API_URL).then((res) => {
        if(res && res.data){
          commit('updatePageContent', res.data);
          // const storageValue = {
          //   timestamp: Date.now(),
          //   data: res.data
          // }
          // sessionStorage.setItem('artworksCachedData', JSON.stringify(storageValue));
        }
        commit('updateLoadingStatus', false);
      }).catch((err) => {
        console.error(err);
      });
    }
  },
  getters: {
    getPageContent(state){
      return state.pageContent || {};
    },
    getLoading(state){
      return state.loading;
    }
  }
}
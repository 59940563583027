<template>
  <div v-if="loaded" class="app-main">
    <header-component :routes="routes"/>
    <home-view v-if="currentRoute === 'home'"/>
    <biography-view v-if="currentRoute === 'biography'"/>
    <artworks-view v-if="currentRoute === 'artworks'"/>
    <artwork-category v-if="currentRoute === 'artworkCategory'"/>
    <exhibitions-view v-if="currentRoute === 'exhibitionsPublications'"/>
    <single-post v-if="currentRoute === 'singlePostAr' || currentRoute === 'singlePostEx'"/>
    <contact-view v-if="currentRoute === 'contact'"/>
    <ErrorPage v-if="currentRoute === '404'"/>
    <footer-component :routes="routes"/>
  </div>
</template>

<script>
  import HeaderComponent from '../components/global/header/HeaderComponent.vue';
  import FooterComponent from '../components/global/footer/FooterComponent.vue';
  import HomeView from './HomeView.vue';
  import BiographyView from './BiographyView.vue';
  import ArtworksView from './ArtworksView.vue';
  import ArtworkCategory from './ArtworkCategory.vue';
  import ExhibitionsView from './ExhibitionsView.vue';
  import SinglePost from './SinglePost.vue';
  import ContactView from './ContactView.vue';
  import ErrorPage from './ErrorPage.vue';

  export default {
    data() {
      return {
        loaded: false,
        currentRoute: this.$route.name,
      };
    },
    name: "PageComponent",
    components: {
      HeaderComponent,
      FooterComponent,
      HomeView,
      BiographyView,
      ArtworksView,
      ArtworkCategory,
      ExhibitionsView,
      SinglePost,
      ContactView,
      ErrorPage
    },
    props: {
      routes: {
        type: Array,
        default: () => {}
      }
    },
    watch:{
      $route (to){
        this.currentRoute = to.name;
      }
    },
    mounted(){
      this.loaded = true;
    }
  };
</script>

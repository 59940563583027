import requestService from "@/services/request-service";
import { API_PATHS } from '@/services/api/config';

export const singlePostModule = {
  namespaced: true,
  state: {
    pageContent: {},
    loading: false,
  },
  mutations: {
    updatePageContent(state, data){
      if(!data) {
        state.pageContent = {};
        return;
      }
      state.pageContent = data;
    },
    updateLoadingStatus(state, flag) {
      state.loading = flag;
    }
  },
  actions: {
    getPageContent({ commit }, { postType, slug }){
      commit('updateLoadingStatus', true);
      let API_URL = postType === 'artworks' ? `${API_PATHS.artworks}&slug=${slug}` : `${API_PATHS.exhibitions}&slug=${slug}`;
      requestService(API_URL).then((res) => {
        if(res && res.data){
          commit('updatePageContent', res.data[0]);
        }
        commit('updateLoadingStatus', false);
      }).catch((err) => {
        console.error(err);
      });
    }
  },
  getters: {
    getPageContent(state){
      return state.pageContent || {};
    },
    getLoading(state){
      return state.loading;
    }
  }
}
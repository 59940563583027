const BASE_API_URL = 'https://admin.hadziboskov.mk/wp-json/';
import { defaultLocale } from "@/plugins/i18n";

const API_PATHS = {
  primaryMenu: `nav-menus/primary${defaultLocale === 'mk' ? `?lang=mk` : ''}`,
  pages: `wp/v2/pages`,
  artworks: `wp/v2/artworks?_embed${defaultLocale === 'mk' ? `&lang=mk` : ''}`,
  artworksCategories: `wp/v2/artwork-categories?_embed${defaultLocale === 'mk' ? `&lang=mk` : ''}`,
  phases: `wp/v2/phases?_embed${defaultLocale === 'mk' ? `&lang=mk` : ''}`,
  materials: `wp/v2/materials?_embed${defaultLocale === 'mk' ? `&lang=mk` : ''}`,
  years: `wp/v2/years?_embed${defaultLocale === 'mk' ? `&lang=mk` : ''}`,
  exhibitions: `wp/v2/exhibitions-publications?_embed${defaultLocale === 'mk' ? `&lang=mk` : ''}`,
  exhibitionsCategories: `wp/v2/exhibition-publication-categories?_embed${defaultLocale === 'mk' ? `&lang=mk` : ''}`,
}

export {
  BASE_API_URL,
  API_PATHS
}
// import { defaultLocale } from '@/plugins/i18n';

export const languageModule = {
  namespaced: true,
  state: {
    defaultLocale: localStorage.getItem("currentLanguage") || 'en',
  },
  mutations: {
    updateDefaultLocale(state, data){
      if(!data) {
        state.defaultLocale = {};
        return;
      }
      state.defaultLocale = data;
    },
  },
  actions: {
    updateLocale({ commit }, locale){
      // let currentLanguage = localStorage.getItem('currentLanguage');

      // if(currentLanguage && currentLanguage === locale) {
      //   return;
      // }
      
      commit('updateDefaultLocale', locale);
    } 
  },
  getters: {
    getDefaultLocale(state){
      return state.defaultLocale || {};
    }
  }
}
<template>
  <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.86042 17.5267L15.1947 10.1924L7.4436 2.4413L9.76676 0.100525L19.8995 10.2332L10.2332 19.8995L7.86042 17.5267Z" fill="url(#paint0_linear_133_1559)"/>
    <path d="M18.038 8.77026L18.038 11.7598L0.100538 11.7598L0.100538 8.77026L18.038 8.77026Z" fill="url(#paint1_linear_133_1559)"/>
    <defs>
      <linearGradient id="paint0_linear_133_1559" x1="19.5821" y1="-4.3033" x2="7.02169" y2="16.2806" gradientUnits="userSpaceOnUse">
        <stop offset="0.0116435" stop-color="#F3E17B"/>
        <stop offset="1" stop-color="#D2AC47"/>
      </linearGradient>
      <linearGradient id="paint1_linear_133_1559" x1="17.5808" y1="8.1053" x2="17.309" y2="12.3533" gradientUnits="userSpaceOnUse">
        <stop offset="0.0116435" stop-color="#F3E17B"/>
        <stop offset="1" stop-color="#D2AC47"/>
      </linearGradient>
    </defs>
  </svg>
</template>
<script>
  export default {
    name: "arrowSvg",
	};
</script>
export default {
  en: {
    routes: {
      home: "Home",
      biography: "Biography",
      artworks: "Artworks",
      sculptures: "Sculptures",
      drawings: "Drawings & Paintings",
      monuments: "Monuments",
      exhibitions: "Exhibitions",
      publications: "Publications",
      exhibitionsPublications: "Exhibitions & Publications",
      news: "News",
      contact: "Contact",
    },
    fullName: "Full name*",
    email: "E-mail*",
    phone: "Phone number",
    message: "Message*",
    seeAll: "See all",
    readMore: "Read more",
    phase: "Phase",
    material: "Material",
    year: "Year",
    all: "All",
    category: "Category",
    send: "Send",
    thankYouTitle: "Thank you!",
    thankYouMessage: "Your message was sent.",
    errorMessage: "There was an error sending your message. Please try again, or contact us on our phone number.",
    requiredField: "Please fill out this field",
    invalidEmail: "Please enter a valid email address",
    invalidPhone: "Please enter a valid phone number",
    noPostsFound: "We didn't find anything.",
    error404: "The page you are looking for doesn't exist.",
    backToHome: "Take me home"
  },
  mk: {
    routes: {
      home: "Почетна",
      biography: "Биографија",
      artworks: "Уметнички дела",
      sculptures: "Скулптури",
      drawings: "Цртежи и принтови",
      monuments: "Споменици",
      exhibitions: "Изложби",
      publications: "Публикации",
      exhibitionsPublications: "Изложби и публикации",
      news: "Новости",
      contact: "Контакт",
    },
    fullName: "Име и презиме*",
    email: "Е-пошта*",
    phone: "Телефон",
    message: "Порака*",
    seeAll: "Види ги сите",
    readMore: "Прочитај повеќе",
    phase: "Фаза",
    material: "Материјал",
    year: "Година",
    all: "Сите",
    category: "Категорија",
    send: "Испрати",
    thankYouTitle: "Ви благодариме!",
    thankYouMessage: "Вашата порака беше успешно испратена.",
    errorMessage: "Настана грешка при испраќање на пораката. Ве молиме обидете се повторно или контактирајте не на нашиот телефон.",
    requiredField: "Полето е задолжително",
    invalidEmail: "Внесете валидна e-mail адреса",
    invalidPhone: "Внесете валиден телефонски број",
    noPostsFound: "Не се пронајдени резултати.",
    error404: "Страницата која ја барате не постои.",
    backToHome: "Кон почетна"
  },
}
<template>
  <div class="biography">
    <Transition name="loading-fade">
      <div v-if="loading" id="loading">
        <div class="loader"></div>
      </div>
    </Transition>
    <template v-if="!loading">
      <hero-section
        :title="getPageTitle"
        :text="pageContent.acf.hero_text || null"
        :image="pageContent.acf.hero_image['url'] || null" />
      <section class="about-section">
        <div class="container">
          <div class="about-wrapper">
            <div class="about-content">
              <div class="about-content_text" v-html="pageContent.acf.biography_text"></div>
            </div>
            
            <div class="about-image">
              <img :src="pageContent.acf.biography_image['url']" alt="">
            </div>
          </div>
        </div>
      </section>
      <section class="phases-section">
        <div v-for="(phase, i) in pageContent.acf.phases"
          :key="i"
          :class="['phase-item', { 'reversed' : i%2===0 || i==0 }]">
          <div class="phase-text">
            <div class="phase-text_inner" v-html="phase.text"></div>
          </div>
          <div class="phase-image">
            <img :src="phase.image.url" alt="">
          </div>
        </div>
      </section>
      <banner-section
        :title="pageContent.acf.banner_title"
        :text="pageContent.acf.banner_text"
        :image="pageContent.acf.banner_image['url']"
        :button="{
          link: 'artworks',
          text: $t('seeAll')
        }"/>
    </template>
  </div>
</template>

<script>
  import HeroSection from '@/components/global/HeroSection.vue';
  import BannerSection from '@/components/global/BannerSection.vue'

  export default {
    data() {
      return {
          colors: ['#def'],
      }
    },
    name: "BiographyView",
    components: {
      HeroSection,
      BannerSection
    },
    computed: {
      pageContent() {
        return this.$store.getters['biographyPageModule/getPageContent'];
      },
      getPageTitle() {
        return (this.pageContent.acf.hero_title && this.pageContent.acf.hero_title !== '') ? this.pageContent.acf.hero_title : this.pageContent.title.rendered;
      },
      loading() {
        return this.$store.getters['biographyPageModule/getLoading'];
      },
    },
    methods: {
      getPageContent() {
        this.$store.dispatch('biographyPageModule/getPageContent');
      },
    },
    created(){
      this.getPageContent();
    },
  };
</script>
<style scoped>
  .about-section {
    padding: 100px 0;
    background-color: var(--black);
  }
  .about-section .about-wrapper {
    display: flex;
    align-items: center;
    gap: 80px;
  }
  .about-section .about-image {
    max-width: 50%;
    flex: 0 0 50%;
    width: 100%;
  }
  .about-section .about-content {
    color: #fff;
  }
  .about-section .about-content .about-content_text {
    margin-bottom: 30px;
  }
  .phases-section {
    /* padding-bottom: 120px; */
  }
  .phase-item {
    display: flex;
    align-items: stretch;
  }
  .phase-item .phase-image {
    flex: 0 0 50%;
    max-width: 50%;
    width: 100%;
    position: relative;
  }
  .phase-item.reversed .phase-image {
    order: 0;
  }
  .phase-item .phase-image img {
    object-fit: cover;
    object-position: center;
    width: 100%;
    height: 100%;
  }
  .phase-item .phase-text {
    flex: 0 0 50%;
    max-width: 50%;
    width: 100%;
    padding: 120px 80px 120px 20px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .phase-item.reversed .phase-text {
    order: 1;
    padding: 120px 20px 120px 80px;
    justify-content: flex-start;
  }
  .phase-item .phase-text .phase-text_inner {
    max-width: 660px;
    width: 100%;
  }
  @media (max-width: 1199px) {
    .about-section .about-wrapper {
      gap: 30px;
    }
  }
  @media (max-width: 991px) {
    .about-section .about-wrapper {
      flex-direction: column;
    }
    .about-section .about-image {
      max-width: 740px;
      flex: 0 0 auto;
    }
    .about-section {
      padding: 80px 0;
    }
    .phases-section {
      padding-bottom: 80px;
    }
    .phase-item {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
    }
    .phase-item.reversed .phase-image,
    .phase-item .phase-image {
      order: 1;
      flex: 0 0 auto;
      width: 100%;
      max-width: 100%;
    }
    .phase-item.reversed .phase-text,
    .phase-item .phase-text {
      order: 0;
      padding: 80px 20px 80px 20px;
      justify-content: flex-start;
      flex: 0 0 auto;
      width: 100%;
      max-width: 100%;
    }
    .phase-item .phase-text .phase-text_inner {
      max-width: 100%;
    }
  }
  @media (max-width: 575px) {
    .about-section {
      padding: 50px 0;
    }
    .phases-section {
      padding-bottom: 50px;
    }
    .phase-item.reversed .phase-text,
    .phase-item .phase-text {
      padding: 50px 20px 50px 20px;
    }
  }
</style>

<template>
  <header :class="activeClass ? 'active': ''" ref="header">
    <div class="container">
      <div class="wrap">
        <div class="logo">
          <localized-link :to="'/'">
            <img src="../../../assets/logo.svg" alt="">
          </localized-link>
        </div>
        <nav-component 
          :nestedRoutes="true"
          :routes="routes"/>
        <div class="header-right">
          <social-media/>
          <language-switcher />
          <button class="menu-trigger" @click="toggleMenu">
            <span></span>
            <span></span>
            <span></span>
          </button>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
	import NavComponent from '../NavComponent.vue';
  import SocialMedia from '../social-media/SocialMedia.vue';
  import LocalizedLink from '@/components/l10n/LocalizedLink.vue';
  import LanguageSwitcher from '../lang-switcher/LanguageSwitcher.vue';

  export default {
    data() {
      return {
        activeClass: false,
      }
    },
    name: "HeaderComponent",
		components: {
			NavComponent,
      SocialMedia,
      LocalizedLink,
      LanguageSwitcher
    },
    props: {
			routes: {
				type: Array,
				default: () => {}
			}
		},
    methods: {
      toggleMenu() {
        let body = document.querySelector('body');
        body.classList.toggle('menu-triggered');
      }
    },
    mounted(){
      window.addEventListener('scroll', () => {
        if(window.scrollY > 100){
          this.activeClass = true;
        } else {
          this.activeClass = false;
        }
      });
      window.addEventListener('load', () => {
        if(window.scrollY > 100){
          this.activeClass = true;
        } else {
          this.activeClass = false;
        }
      })
    },
    
    watch: {
      $route (to, from){
        if(to !== from){
          let body = document.querySelector('body');
          let submenu = document.querySelector('.sub-menu');
          body.classList.remove('menu-triggered');
          submenu.classList.remove('active');
        }
      }
    },
	};
</script>
<style>
  header {
    /* background-color: black; */
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    padding: 30px 0;
    z-index: 100;
    transition: .3s ease-in-out;
  }
  header.active {
    background-color: var(--black);
    padding: 20px 0;
  }
  header .logo {
    max-width: 156px;
    width: 100%;
  }
  header .header-right {
    display: flex;
    gap: 30px;
    align-items: center;
  }
  header .header-right .menu-trigger {
    display: none;
  }
  @media (max-width: 1199px) {
    header .logo {
      z-index: 101;
    }
    header .header-right .menu-trigger {
      display: block;
      width: 30px;
      height: 23px;
      appearance: none;
      -webkit-appearance: none;
      border: none;
      outline: none;
      position: relative;
      z-index: 101;
    }
    header .header-right .menu-trigger span {
      display: block;
      width: 100%;
      height: 2px;
      background-color: #fff;
      position: absolute;
      left: 0;
    }
    header .header-right .menu-trigger span:nth-child(1) {
      top: 0;
    }
    header .header-right .menu-trigger span:nth-child(2) {
      top: 50%;
      transform: translateY(-50%);
    }
    header .header-right .menu-trigger span:nth-child(3) {
      bottom: 0;
    }
  }
  @media (max-width: 991px) {
    header .header-right .social {
      display: none;
    }
  }
</style>

<template>
  <router-view :routes="routes"/>
</template>

<script>
  import requestService from '@/services/request-service';
  import { API_PATHS } from '@/services/api/config';

  export default {
    data() {
      return {
        routes: [],
      };
    },
    methods: {
      getRoutes(){
        requestService(API_PATHS.primaryMenu).then((res) => {
          const routes = [];
          if(res.data){
            res.data.map((r) => {
              let mappedRoute = {
                path: r.slug,
                title: r.title,
                children: [],
              }
              if(r.children){
                r.children.forEach((cr) => {
                  let childRoute = {
                    path: `${r.slug}/${cr.slug}`,
                    title: cr.title,
                  }
                  mappedRoute['children'].push(childRoute);
                })
                
              }
              routes.push(mappedRoute);
            });
            this.routes = routes;
          }
        });
      }
    },
    computed: {
    },
    mounted(){
      this.getRoutes();
    },
  };
</script>
<style>
  @import './assets/css/reset.min.css';
  @import './assets/fonts/fonts.css';
  @import './assets/css/main.css';
</style>

<template>
  <div class="biography">
    <Transition name="loading-fade">
      <div v-if="loading" id="loading">
        <div class="loader"></div>
      </div>
    </Transition>
    <template v-if="!loading">
      <hero-section
        :title="getPageTitle"
        :text="pageContent.acf.hero_text || null"
        :image="pageContent.acf.hero_image['url'] || null" />
      <section class="contact-section">
        <div class="container-sm">
          <div class="mail-sent" v-if="sent">
            <h2>{{ $t('thankYouTitle') }}</h2>
            <p>{{ $t('thankYouMessage') }}</p>
          </div>
          <template v-else>
            <div class="content">
              <h2>{{ pageContent.acf.contact_title }}</h2>
              <p>{{ pageContent.acf.contact_text }}</p>
              <div class="call-info">
                <div class="email">
                  <img src="../assets/icons/mail.png" alt="E-mail">
                  <a :href="`mailto:${pageContent.acf.email}`">{{ pageContent.acf.email }}</a>
                </div>
                <div class="phone">
                  <img src="../assets/icons/phone.png" alt="Phone">
                  <a :href="`tel:${pageContent.acf.phone}`">{{ pageContent.acf.phone }}</a>
                </div>
              </div>
            </div>
            <form @submit.prevent="onSubmit">
              <div class="input-wrapper">
                <label for="">
                  <p>{{ $t('fullName') }}</p>
                  <input class="form-control" name="contact_name" v-model="form.contact_name" type="text" :placeholder="$t('fullName')">
                </label>
                <span class="input-error" v-if="formErrors.name">{{ $t(formErrors.name) }}</span>
              </div>
              <div class="input-wrapper">
                <label for="">
                  <p>{{ $t('email') }}</p>
                  <input class="form-control" name="contact_email" v-model="form.contact_email" type="email" :placeholder="$t('email')">
                </label>
                <span class="input-error" v-if="formErrors.email">{{ $t(formErrors.email) }}</span>
              </div>
              <div class="input-wrapper">
                <label for="">
                  <p>{{ $t('phone') }}</p>
                  <input class="form-control" name="contact_phone" v-model="form.contact_phone" type="tel" :placeholder="$t('phone')">
                </label>
                <span class="input-error" v-if="formErrors.phone">{{ $t(formErrors.phone) }}</span>
              </div>
              <div class="input-wrapper">
                <label for="">
                  <p>{{ $t('message') }}</p>
                  <textarea class="form-control" name="contact_message" v-model="form.contact_message" id="" :placeholder="$t('message')"></textarea>
                </label>
                <span class="input-error" v-if="formErrors.message">{{ $t(formErrors.message) }}</span>
              </div>
              <p class="error-message" v-if="error">{{ $t('errorMessage') }}</p>
              <div class="contact-button">
                <button-component 
                  :html="'submit'"
                  class="black">
                  {{ $t('send') }}
                </button-component>
              </div>
            </form>
          </template>
        </div>
      </section>
    </template>
  </div>
</template>

<script>
  import HeroSection from '@/components/global/HeroSection.vue';
  import ButtonComponent from '@/components/global/button/ButtonComponent.vue';
  import axios from 'axios';
  import { BASE_API_URL } from '@/services/api/config';

  export default {
    data() {
      return {
        sent: false,
        error: false,
        form: {
          contact_name: "",
          contact_email: "",
          contact_phone: "",
          contact_message: "",
        },
        formErrors: {
          name: '',
          email: '',
          phone: '',
          message: '',
        }
      }
    },
    name: "ContactView",
    components: {
      HeroSection,
      ButtonComponent
    },
    computed: {
      pageContent() {
        return this.$store.getters['contactPageModule/getPageContent'];
      },
      getPageTitle() {
        return (this.pageContent.acf.hero_title && this.pageContent.acf.hero_title !== '') ? this.pageContent.acf.hero_title : this.pageContent.title.rendered;
      },
      loading() {
        return this.$store.getters['contactPageModule/getLoading'];
      },
    },
    methods: {
      getPageContent() {
        this.$store.dispatch('contactPageModule/getPageContent');
      },
      onSubmit() {        
        if(!this.validateForm()){
          return;
        }
        axios.post(`${BASE_API_URL}contact/v1/send`, this.serialize(this.form))
        .then((res) => {
          if(res && res.data){
            if(res.data.status === 200) {
              this.sent = true;
              this.error = false;
            } else {
              this.sent = false;
              this.error = true;
            }
          }
        })
        .catch((err) => {
          console.error(err);
          this.error = true;
        })
      },
      validateForm(){
        this.formErrors.name = '';
        this.formErrors.email = '';
        this.formErrors.phone = '';
        this.formErrors.message = '';

        if (!this.form.contact_name) {
          this.formErrors.name = this.$t('requiredField');
        }
        if (!this.form.contact_email) {
          this.formErrors.email = this.$t('requiredField');
        } else if (!this.validEmail(this.form.contact_email)) {
          this.formErrors.email = this.$t('invalidEmail');
        }
        if(this.form.contact_phone && !this.validPhone(this.form.contact_phone)){
          this.formErrors.phone = this.$t('invalidPhone');
        }
        if (!this.form.contact_message) {
          this.formErrors.message = this.$t('requiredField');
        }

        if(this.formErrors.name || this.formErrors.email || this.formErrors.phone || this.formErrors.message) {
          return false;
        }
        return true;
      },
      validEmail: function (email) {
        var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
      },
      validPhone: function (phone) {
        var re = /^[0-9]*$/;
        return re.test(phone);
      },
      serialize(obj) {
        var str = [];
        for (var p in obj)
          if (Object.prototype.hasOwnProperty.call(obj, p)) {
            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
          }
        return str.join("&");
      },
    },
    created(){
      this.getPageContent();
    },
  };
</script>
<style scoped>
  .contact-section {
    padding: 100px 0;
  }
  .contact-section .content {
    text-align: center;
    margin-bottom: 40px;
  }
  .contact-section .content .call-info {
    display: flex;
    align-items: center;
    gap: 30px;
    justify-content: center;
  }
  .contact-section .content .call-info .phone,
  .contact-section .content .call-info .email {
    display: flex;
    align-items: center;
    gap: 10px;
    text-decoration: underline;
    font-weight: 600;
  }
  .contact-section .content .call-info .email img {
    max-width: 25px
  }
  .contact-section .content .call-info .phone img {
    max-width: 20px;
  }
  .input-wrapper {
    width: 100%;
  }
  .input-wrapper:not(:last-child) {
    margin-bottom: 30px;
  }
  .input-wrapper p {
    text-indent: 25px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  .input-wrapper label {
    display: block;
  }
  .form-control {
    width: 100%;
    height: 60px;
    border-radius: 100vw;
    border: 2px solid var(--black);
    padding: 10px 25px;
  }
  textarea.form-control {
    height: 130px;
    resize: none;
    border-radius: 25px;
  }
  .contact-button {
    display: flex;
    justify-content: center
  }
  .error-message {
    /* color: red; */
    border: 2px solid red;
    padding: 10px;
  }
  .input-error {
    font-size: .75em;
    color: red;
    margin-top: 3px;
    margin-left: 25px;
    display: block;
  }
  @media (max-width: 991px) {
    .contact-section {
      padding: 80px 0;
    }
  }
  @media (max-width: 575px) {
    .contact-section {
      padding: 50px 0;
    }
    .contact-section .content .call-info {
      display: flex;
      align-items: center;
      gap: 15px;
      justify-content: center;
      flex-direction: column;
    }
  }
</style>

<template>
  <svg viewBox="0 0 11 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.1751 0.875C6.94847 0.875 5.77208 1.36228 4.90473 2.22963C4.03737 3.09699 3.5501 4.27337 3.5501 5.5V8.075H1.0751C0.951098 8.075 0.850098 8.175 0.850098 8.3V11.7C0.850098 11.824 0.950098 11.925 1.0751 11.925H3.5501V18.9C3.5501 19.024 3.6501 19.125 3.7751 19.125H7.1751C7.2991 19.125 7.4001 19.025 7.4001 18.9V11.925H9.8971C10.0001 11.925 10.0901 11.855 10.1151 11.755L10.9651 8.355C10.9735 8.32182 10.9741 8.28717 10.9671 8.25369C10.9601 8.22021 10.9455 8.18877 10.9245 8.16178C10.9034 8.13478 10.8765 8.11293 10.8458 8.09789C10.8151 8.08286 10.7813 8.07503 10.7471 8.075H7.4001V5.5C7.4001 5.39823 7.42014 5.29745 7.45909 5.20342C7.49804 5.10939 7.55512 5.02396 7.62709 4.95199C7.69905 4.88003 7.78449 4.82294 7.87852 4.78399C7.97255 4.74505 8.07332 4.725 8.1751 4.725H10.7751C10.8991 4.725 11.0001 4.625 11.0001 4.5V1.1C11.0001 0.976 10.9001 0.875 10.7751 0.875H8.1751Z" fill="white"/>
  </svg>
</template>

<script>
  export default {
    name: "fbSvg",
	};
</script>
<style scoped>
  svg {
    height: 18px;
  }
</style>
<template>
  <div class="custom-select" :tabindex="tabindex" @blur="open = false">
    <div class="selected" :class="{ open: open }" @click="open = !open">
      {{ thisSelectedVal }}
    </div>
    <div class="items" :class="{ selectHide: !open }">
      <div
        v-for="(option, i) of Object.keys(options)"
        :key="i"
        @click="
          selected = option;
          selectedVal = options[option];
          open = false;
          $emit('change', [option]);
        ">
        {{ options[option] }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      selected: this.default
        ? this.default
        : Object.keys(this.options).length > 0 
        ? Object.keys(this.options)[0]
        : null,
      selectedVal: this.default
        ? this.options[this.default]
        : Object.keys(this.options).length > 0 
        ? this.options[Object.keys(this.options)[0]]
        : null,
      open: false,
    };
  },
  name: 'CustomSelect',
  props: {
    options: {
      type: Object,
      required: true,
    },
    default: {
      type: [String, Number],
      required: false,
      default: null,
    },
    tabindex: {
      type: Number,
      required: false,
      default: 0,
    },
    resetFilters: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    thisSelectedVal(){
      return this.selectedVal || (this.default
        ? this.options[this.default]
        : Object.keys(this.options).length > 0 
        ? this.options[Object.keys(this.options)[0]]
        : null);
    },
    thisResetFilters() {
      return this.resetFilters;
    }
  },
  watch: {
    thisResetFilters(newValue) {
      if(newValue === true){
        this.selected = Object.keys(this.options)[0];
        this.selectedVal = this.options[Object.keys(this.options)[0]];
      }
    }
  }
};
</script>

<style scoped>
  .selectHide {
    display: none;
  }
</style>
<template>
  <div class="home">
    <Transition name="loading-fade">
      <div v-if="loading" id="loading">
        <div class="loader"></div>
      </div>
    </Transition>
    <template v-if="!loading">
      <hero-section
        :title="getPageTitle"
        :text="pageContent.acf.hero_text || null"
        :image="pageContent.acf.hero_image['url'] || null"
        :button="true" />
      <section class="about-section">
        <div class="container">
          <div class="about-wrapper">
            <div class="about-image">
              <img :src="pageContent.acf.about_image['url']" alt="">
            </div>
            <div class="about-content">
              <div class="about-content_text" v-html="pageContent.acf.about_text"></div>
              <button-component 
                :link="'biography'"
                :type="'text-arrow'">
                {{ $t('readMore') }}
              </button-component>
            </div>
          </div>
        </div>
      </section>
      <section class="post-type-listing">
        <div class="container">
          <dl class="accordion">
            <div id="post-type-listing-artworks" class="accordion-item" :class="isOpen.artworks ? 'active' : ''">
              <dt class="accordion-item-title">
                <div @click="toggleAccordionItem" id="artworks" class="accordion-item-trigger">
                  <h2 class="accordion-item-title-text">{{ $t('routes.artworks') }}</h2>
                  <span class="accordion-item-trigger-icon">
                    <accordionArrow/>
                  </span>
                </div>
              </dt>
              <Transition
                name="accordion-item"
                @enter="startTransition"
                @after-enter="endTransition"
                @before-leave="startTransition"
                @after-leave="endTransition">
                <dd v-if="isOpen.artworks" class="accordion-item-details">
                  <div class="accordion-under-title">
                    <div class="post-type-categories">
                      <filter-buttons
                        :data="artworkCategories"
                        :postType="'artworks'"
                        :filterBySlug="true"
                        @filterRequest="getCategoryPosts"/>
                    </div>
                    <button-component 
                      :link="'artworks'"
                      :type="'text-arrow'"
                      class="black">
                      {{ $t('seeAll') }}
                    </button-component>
                  </div>
                  <div class="accordion-item-details-inner">
                    <post-item
                      v-for="post in getArtworks"
                      :key="`artwork-${post.id}`"
                      :post="post"
                      :postTypeSlug="'artworks/artwork-categories'"/>
                  </div>
                </dd>
              </Transition>
            </div>
            <div id="post-type-listing-artworks" class="accordion-item" :class="isOpen.exhibitions ? 'active' : ''">
              <dt class="accordion-item-title">
                <div @click="toggleAccordionItem" id="exhibitions" class="accordion-item-trigger">
                  <h2 class="accordion-item-title-text">{{ $t('routes.exhibitions') }}</h2>
                  <span class="accordion-item-trigger-icon">
                    <accordionArrow/>
                  </span>
                </div>
              </dt>
              <Transition
                name="accordion-item"
                @enter="startTransition"
                @after-enter="endTransition"
                @before-leave="startTransition"
                @after-leave="endTransition">
                <dd v-if="isOpen.exhibitions" class="accordion-item-details">
                  <div class="accordion-under-title">
                    <button-component 
                      :link="'exhibitions-and-publications?exhibitions'"
                      :type="'text-arrow'"
                      class="black">
                      {{ $t('seeAll') }}
                    </button-component>
                  </div>
                  <div class="accordion-item-details-inner">
                    <post-item
                      v-for="post in getExhibitions"
                      :key="`exhibition-${post.id}`"
                      :post="post"
                      :postTypeSlug="'exhibitions-and-publications'"/>
                  </div>
                </dd>
              </Transition>
            </div>
            <div id="post-type-listing-artworks" class="accordion-item" :class="isOpen.publications ? 'active' : ''">
              <dt class="accordion-item-title">
                <div @click="toggleAccordionItem" id="publications" class="accordion-item-trigger">
                  <h2 class="accordion-item-title-text">{{ $t('routes.publications') }}</h2>
                  <span class="accordion-item-trigger-icon">
                    <accordionArrow/>
                  </span>
                </div>
              </dt>
              <Transition
                name="accordion-item"
                @enter="startTransition"
                @after-enter="endTransition"
                @before-leave="startTransition"
                @after-leave="endTransition">
                <dd v-if="isOpen.publications" class="accordion-item-details">
                  <div class="accordion-under-title">
                    <button-component 
                      :link="'exhibitions-and-publications?publications'"
                      :type="'text-arrow'"
                      class="black">
                      {{ $t('seeAll') }}
                    </button-component>
                  </div>
                  <div class="accordion-item-details-inner">
                    <post-item
                      v-for="post in getPublications"
                      :key="`publication-${post.id}`"
                      :post="post"
                      :postTypeSlug="'exhibitions-and-publications'"/>
                  </div>
                </dd>
              </Transition>
            </div>
          </dl>
        </div>
      </section>
    </template>
  </div>
</template>

<script>
  import HeroSection from '@/components/global/HeroSection.vue';
  import ButtonComponent from '@/components/global/button/ButtonComponent.vue';
  import PostItem from '@/components/global/post-listing/PostItem.vue';
  import accordionArrow from '@/components/global/button/accordionArrow.vue';
  // import {ref} from 'vue';
  import FilterButtons from '../components/global/filters/FilterButtons.vue';
  import { defaultLocale } from '@/plugins/i18n';

  export default {
    data() {
      return {
        isOpen: {
          artworks: false,
          exhibitions: false,
          publications: false,
        },
      }
    },
    name: "HomeView",
    components: {
      HeroSection,
      ButtonComponent,
      PostItem,
      accordionArrow,
      FilterButtons
    },
    computed: {
      pageContent() {
        return this.$store.getters['homePageModule/getPageContent'];
      },
      getPageTitle() {
        return (this.pageContent.acf.hero_title && this.pageContent.acf.hero_title !== '') ? this.pageContent.acf.hero_title : this.pageContent.title.rendered;
      },
      getArtworks() {
        const artworks = this.$store.getters['postTypeModule/getArtworks'];
        return artworks.slice(0, 3);
      },
      getExhibitions() {
        const allExhibitions = this.$store.getters['postTypeModule/getExhibitions'];
        let id = defaultLocale === 'en' ? 6 : 32; 
        const exhibitions = allExhibitions.filter((i) => i['exhibition-publication-categories'].includes(id));
        return exhibitions.slice(0, 3);
      },
      getPublications() {
        const allExhibitions = this.$store.getters['postTypeModule/getExhibitions'];
        let id = defaultLocale === 'en' ? 7 : 33; 
        const publications = allExhibitions.filter((i) => i['exhibition-publication-categories'].includes(id));
        return publications.slice(0, 4);
      },
      artworkCategories() {
        return this.$store.getters['postTypeModule/getArtworksCategories'];
      },
      loading() {
        return this.$store.getters['homePageModule/getLoading'];
      },
    },
    methods: {
      getPageContent() {
        this.$store.dispatch('homePageModule/getPageContent');
      },
      getPostTypes(postType = 'all', perPage = null, pageNumber = null, categories = [], filterBySlug = false, postTypeFilter = [], filterByPostType = false, getInitial = false) {
        this.$store.dispatch('postTypeModule/getPostType', {
          postType,
          perPage,
          pageNumber,
          categories,
          filterBySlug,
          postTypeFilter,
          filterByPostType,
          getInitial
        });
      },
      getCategoryPosts({postType, btnId}){
        // const postType = event.srcElement.dataset.postType;
        const catId = btnId ? [btnId] : [];
        this.getPostTypes(postType, null, null, catId, true, null, false, false);
      },
      getArtworkCategories(){
        this.$store.dispatch('postTypeModule/getCategories', {
          postType: 'artworks',
        });
      },
      toggleAccordionItem(event) {
        Object.keys(this.isOpen).forEach((i) => {
          if(i === event.currentTarget.id) {
            this.isOpen[i] = !this.isOpen[i];
          } else {
            this.isOpen[i] = false;
          }
        });
      },
      startTransition(el) {
        el.style.height = el.scrollHeight + 'px';
      },
      endTransition(el) {
        el.style.height = '';
      },
    },
    created(){
      this.getPageContent();
      this.getPostTypes();
      this.getArtworkCategories();
    },
  };
</script>
<style scoped>
  .about-section {
    padding: 100px 0;
  }
  .about-section .about-wrapper {
    display: flex;
    align-items: center;
  }
  .about-section .about-image {
    max-width: 740px;
    flex: 0 0 740px;
    width: 100%;
  }
  .about-section .about-content {
    background-color: var(--black);
    color: #fff;
    padding: 80px 70px;
    position: relative;
    margin-left: -110px;
  }
  .about-section .about-content .about-content_text {
    margin-bottom: 30px;
  }
  .post-type-listing {
    padding-bottom: 120px;
  }
  .accordion-item-title {
    border-top: 1px solid var(--black);
  }
  .accordion-item-title .accordion-item-trigger {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
  }
  .accordion-item-trigger-icon {
    transform: rotate(0);
    transform-origin: center;
    transition: all .3s ease-in-out;
    width: 140px;
    height: 140px;
  }
  .accordion-item-title:hover .accordion-item-trigger-icon,
  .accordion-item.active .accordion-item-trigger-icon {
    transform: rotate(-180deg);
  }
  .accordion-item-title-text {
    margin-bottom: 0;
    font-size: 80px;
    transition: all .3s ease-in-out;
    padding: 30px 0;
    text-transform: uppercase;
  }
  .accordion-item-title:hover .accordion-item-title-text,
  .accordion-item.active .accordion-item-title-text {
    color: var(--gold);
  }
  .accordion-item-details {
    padding-bottom: 60px;
  }
  .accordion-item-details .accordion-under-title {
    margin-bottom: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .accordion-item-details .accordion-under-title > .btn {
    margin-left: auto;
  }
  .accordion-item-details-inner {
    display: flex;
    gap: 40px;
  }
  .accordion-item-details-inner >>> .post-item {
    width: 100%;
    flex: 0 0 calc(33.3333% - 27px);
  }
  .accordion-item-enter-active, .accordion-item-leave-active {
    will-change: height;
    transition: height 0.2s ease;
    overflow: hidden;
  }
  .accordion-item-enter-from, .accordion-item-leave-to {
    height: 0 !important;
  }
  .accordion-item-enter-from {
    padding-bottom: 60px;
  }
  .accordion-item-leave-to {
    padding: 0;
  }
  @media (max-width: 1199px) {
    .about-section .about-image {
      max-width: 600px;
      flex: 0 0 600px;
    }
    .accordion-item-title-text {
      font-size: 65px;
      padding: 30px 0;
    }
    .accordion-item-trigger-icon {
      width: 100px;
      height: 100px;
    }
  }
  @media (max-width: 991px) {
    .about-section .about-wrapper {
      flex-direction: column;
    }
    .about-section .about-image {
      flex: 0 0 auto;
    }
    .about-section .about-content {
      padding: 80px 50px;
      margin-left: 0;
    }
    .accordion-item-title-text {
      font-size: 50px;
    }
    .post-type-categories {
      display: none;
    }
    .accordion-item-details-inner {
      gap: 20px;
      flex-wrap: wrap;
    }
    .accordion-item-details-inner >>> .post-item {
      width: 100%;
      flex: 0 0 calc(50% - 10px);
      margin-bottom: 20px;
    }
  }
  @media (max-width: 767px) {
    .about-section {
      padding: 50px 0;
    }
    .accordion-item-details-inner >>> .post-item {
      width: 100%;
      flex: 0 0 100%;
    }
    .post-type-listing {
      padding-bottom: 50px;
    }
  }
  @media (max-width: 575px) {
    .about-section .about-content {
      padding: 50px 20px;
    }
    .accordion-item-title-text {
      font-size: 30px;
      padding: 30px 0;
    }
    .accordion-item-trigger-icon {
      width: 50px;
      height: 50px;
    }
  }
</style>
<style>
  .accordion-item-title .accordion-item-trigger-icon svg path {
    transition: all .3s ease-in-out;
  }
  .accordion-item-title:hover .accordion-item-trigger-icon svg path,
  .accordion-item.active .accordion-item-trigger-icon svg path {
    fill: var(--gold);
  }
</style>

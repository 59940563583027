<template>
  <nav class="main-nav">
    <ul class="menu-primary">
      <li v-for="route in routes"
				:key="route.ID"
				:class="[
					'menu-item',
					{ 'has-children' : route.children && route.children.length }
				]">
				<localized-link :to="(route.query_id && route.query_id !== null) ? `${route.path}?id=${route.query_id}` : route.path">
					{{ route.title }}
				</localized-link>
				<span v-if="route.children && route.children.length && nestedRoutes" class="sub-menu-toggle" @click="subMenuClick($event)"></span>
				<ul class="sub-menu" v-if="route.children && route.children.length && nestedRoutes" ref="subMenu">
					<li v-for="cRoute in route.children"
						:key="cRoute.ID"
						:class="[
							'menu-item',
							{ 'has-children' : cRoute.children && cRoute.children.length }
						]">
						<localized-link :to="(cRoute.query_id && cRoute.query_id !== null) ? `${cRoute.path}?id=${cRoute.query_id}` : cRoute.path">
							{{ cRoute.title }}
						</localized-link>
					</li>
				</ul>
			</li>
    </ul>
  </nav>
</template>

<script>
	import LocalizedLink from '../l10n/LocalizedLink.vue';

  export default {
    name: "NavComponent",
		props: {
			nestedRoutes: {
				type: Boolean,
				default: false
			},
			routes: {
				type: Array,
				default: () => {}
			}
		},
		components: {
			LocalizedLink,
    },
		methods: {
			subMenuClick(e){
				console.log(e);
				e.target.nextSibling.classList.toggle('active');
			}
		}
	};
</script>
<style scoped>
	.menu-primary {
		display: flex;
		align-items: center;
	}
	.menu-primary li {
		position: relative;
		display: flex;
		align-items: center;
		gap: 3px;
		cursor: pointer;
		transition: all .3s ease-in-out;
		font-weight: 600;
	}
	.menu-primary > li {
		padding: 6px 0;
	}
	.menu-primary > li:not(:last-child) {
		margin-right: 40px;
	}
	.menu-primary > li > a {
		color: #fff;
	}
	.menu-primary > li:hover > a {
		color: var(--gold);
		transition: all .3s ease-in-out;
	}
	.menu-primary li > .sub-menu-toggle {
		width: 24px;
		height: 24px;
		background-image: url('../../assets/icons/dropdown-arrow.svg');
	}
	.menu-primary li > .sub-menu {
		opacity: 0;
		visibility: hidden;
		position: absolute;
		top: 100%;
		left: 0;
		white-space: nowrap;
		transition: all .3s ease-in-out;
		background-color: #fff;
		padding: 20px 0;
	}
	.menu-primary li:hover > .sub-menu {
		opacity: 1;
		visibility: visible;
	}
	.menu-primary li > .sub-menu > li {
		padding: 8px 28px;
		transition: all .3s ease-in-out;
	}
	.menu-primary li > .sub-menu > li:hover {
		color: var(--gold);
	}
	@media (max-width: 1199px){
    header .menu-primary {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100vh;
      background-color: var(--black);
      flex-direction: column;
			justify-content: center;
			align-items: flex-start;
			padding: 80px 0;
			z-index: 100;
			opacity: 0;
			visibility: hidden;
			transition: .3s ease-in-out;
    }
    .menu-triggered header .menu-primary {
      opacity: 1;
			visibility: visible;
    }
		header .menu-primary > li {
			font-size: 28px;
			text-align: center;
			padding: 10px 0;
			flex-wrap: wrap;
			width: 100%;
			text-align: center;
			justify-content: center;
		}
		header .menu-primary > li:not(.has-children) {
			padding: 10px 20px;
		}
		header .menu-primary > li.has-children {
			flex-wrap: wrap;
			justify-content: center;
			gap: 10px;
		}
		header .menu-primary > li:not(:last-child) {
			margin-right: 0;
		}
		header .menu-primary li > .sub-menu {
			position: relative;
			top: auto;
			left: auto;
			flex: 0 0 100%;
			width: 100%;
			background-color: #141414;
			color: #fff;
			max-height: 0;
			transition-property: max-height, opacity, visibility;
			transition-timing-function: ease-in-out;
			transition-duration: .1s, 0s, 0s;
			opacity: 0;
			visibility: hidden;
			padding: 0;
		}
		header .menu-primary li:hover > .sub-menu {
			opacity: 0;
			visibility: hidden;
		}
		header .menu-primary li > .sub-menu.active {
			transition-duration: .3s, .3s, .3s;
			transition-delay: 0s, .1s;
			max-height: 800px;
			opacity: 1;
			visibility: visible;
			padding: 20px 0;
		}
		header .menu-primary li > .sub-menu li {
			justify-content: center;
			font-size: 20px;
		}
  }
	@media (max-width: 991px) {
		footer .menu-primary {
      flex-direction: column;
      justify-content: center;
      align-items: center
    }
    footer .menu-primary > li {
      padding: 10px 0;
    }
    footer .menu-primary > li:not(:last-child) {
      margin-right: 0;
    }
		.footer-bottom {
			font-size: 12px;
		}
	}
	@media (max-height: 650px) {
		header .menu-primary {
			padding-top: 100px;
			justify-content: flex-start;
			overflow-y: auto;
			overflow-x: hidden;
    }
	}
</style>

<template>
  <div class="artwork-category">
    <Transition name="loading-fade">
      <div v-if="loading" id="loading">
        <div class="loader"></div>
      </div>
    </Transition>
    <template v-if="!loading">
      <hero-section
        :title="getPageTitle"
        :text="pageContent.acf.hero_text || null"
        :image="pageContent.acf.hero_image['url'] || null" />
      <section class="post-listing-section">
        <div class="container">
          <filter-buttons
            :data="getAllCategories"
            :postType="'artworks'"/>
          <div v-if="loadingPosts" class="loading-posts">
            <span></span>
          </div>
          <template v-else>
            <post-listing
              :data="getArtworks"
              :catSlugs="getCatSlugs"
              :postTypeSlug="'artworks'"/>
            <pagination-component
              :totalNumberItems="getInitialNumberOfArtworks"
              :perPage="perPage" />
          </template>
        </div>
      </section>
      <banner-section
        :title="pageContent.acf.banner_title"
        :text="pageContent.acf.banner_text"
        :image="pageContent.acf.banner_image['url']"
        :button="{
          link: 'exhibitions-and-publications',
          text: $t('seeAll')
        }"/>
    </template>
  </div>
</template>

<script>
  import HeroSection from '@/components/global/HeroSection.vue';
  import BannerSection from '@/components/global/BannerSection.vue';
  import FilterButtons from '@/components/global/filters/FilterButtons.vue';
  import PostListing from '@/components/global/post-listing/PostListing.vue';
  import PaginationComponent from '@/components/global/pagination/PaginationComponent.vue';

  export default {
    data() {
      return {
        perPage: 12,
      }
    },
    name: "ArtworkCategory",
    components: {
      HeroSection,
      BannerSection,
      FilterButtons,
      PostListing,
      PaginationComponent
    },
    computed: {
      pageContent() {
        return this.$store.getters['artworksPageModule/getPageContent'];
      },
      getPageTitle() {
        return (this.pageContent.acf.hero_title && this.pageContent.acf.hero_title !== '') ? this.pageContent.acf.hero_title : this.pageContent.title.rendered;
      },
      getArtworks() {
        const artworks = this.$store.getters['postTypeModule/getArtworks'];
        return artworks;
      },
      getInitialNumberOfArtworks() {
        const artworks = this.$store.getters['postTypeModule/getInitialNumberOfArtworks'];
        return artworks;
      },
      getAllCategories() {
        const phases = this.$store.getters['postTypeModule/getPhases'];
        const materials = this.$store.getters['postTypeModule/getMaterials'];
        const years = this.$store.getters['postTypeModule/getYears'];

        const allCategories = () => {
          let obj = {};
          obj['phases'] = {};
          obj['phases']['data'] = phases.reduce((a, v) => ({ ...a, [v.id]: v.name}), {});
          obj['phases']['data'][0] = this.$t('phase');
          obj['phases']['name'] = this.$t('phase');
          obj['phases']['slug'] = 'phases';

          obj['materials'] = {};
          obj['materials']['data'] = materials.reduce((a, v) => ({ ...a, [v.id]: v.name}), {});
          obj['materials']['data'][0] = this.$t('material');
          obj['materials']['name'] = this.$t('material');
          obj['materials']['slug'] = 'materials';

          obj['years'] = {};
          obj['years']['data'] = years.reduce((a, v) => ({ ...a, [v.id]: v.name}), {});
          obj['years']['data'][0] = this.$t('year');
          obj['years']['name'] = this.$t('year');
          obj['years']['slug'] = 'years';

          obj['filterType'] = 'select';
          return obj;
        }
        return allCategories();
      },
      getCatSlugs(){
        const catSlugs = this.$store.getters['postTypeModule/getArtworksCategories'];
        let mappedCats = {};
        catSlugs.map((c) => {
          mappedCats[c.id] = c.slug;
          return;
        });
        return mappedCats;
      },
      loading() {
        return this.$store.getters['artworksPageModule/getLoading'];
      },
      loadingPosts(){
        return this.$store.getters['postTypeModule/getLoading'];
      }
    },
    methods: {
      getPageContent() {
        this.$store.dispatch('artworksPageModule/getPageContent');
      },
      getPostTypes(postType = 'artworks', perPage = null, pageNumber = null, categories = [], filterBySlug = false, postTypeFilter = [], filterByPostType = false, getInitial = false) {
        this.$store.dispatch('postTypeModule/getPostType', {
          postType,
          perPage,
          pageNumber,
          categories,
          filterBySlug,
          postTypeFilter,
          filterByPostType,
          getInitial
        });
      },
      getCategories(postType = 'artworks', taxonomy = null){
        this.$store.dispatch('postTypeModule/getCategories', {
          postType,
          taxonomy
        });
      }
    },
    created(){
      this.getPageContent();
      this.getCategories('artworks', 'phases');
      this.getCategories('artworks', 'materials');
      this.getCategories('artworks', 'years');
      this.getCategories('artworks', null);
      
      if(Object.keys(this.$route.query).length > 0) {
        const checkingArr = ['phases', 'materials', 'years'];
        let catParams = Object.keys(this.$route.query).map(key => {
          if(!checkingArr.includes(key)){
            return null;
          }
          let q = key+"="+this.$route.query[key];
          return q;
        });
        catParams = catParams.filter(i => i !== null);
        catParams = catParams.length > 0 ? catParams.join('&') : null;
        const ptParams = catParams && catParams[0] === null ? null : catParams;
        let pageQ = 1;
        if(this.$route.query['page']){
          pageQ = this.$route.query['page'];
        }
        if(Object.keys(this.$route.query).length == 1 && this.$route.query['page']){
          this.getPostTypes('artworks', this.perPage, pageQ, [this.$route.params.cat_slug], true, ptParams, true, false);
        } else {
          this.getPostTypes('artworks', this.perPage, pageQ, [this.$route.params.cat_slug], true, ptParams, true, true);
        }
      } else {
        this.getPostTypes('artworks', this.perPage, null, [this.$route.params.cat_slug], true, null, false, true);
      }
    },
    watch: {
      $route (to, from){
        if(to !== from){
          let newVal = to.query;
          if(Object.keys(newVal).length > 0) {
            const checkingArr = ['phases', 'materials', 'years'];
            let catParams = Object.keys(newVal).map(key => {
              if(!checkingArr.includes(key)){
                return null;
              }
              let q = key+"="+newVal[key];
              return q;
            });
            catParams = catParams.filter(i => i !== null);
            catParams = catParams.length > 0 ? catParams.join('&') : null;
            const ptParams = catParams && catParams[0] === null ? null : catParams;
            let pageQ = 1;
            if(newVal['page']){
              pageQ = newVal['page'];
            }
            if(Object.keys(newVal).length == 1 && newVal['page']){
              this.getPostTypes('artworks', this.perPage, pageQ, [to.params.cat_slug], true, ptParams, true, false);
            } else {
              this.getPostTypes('artworks', this.perPage, pageQ, [to.params.cat_slug], true, ptParams, true, true);
            }
          } else {
            this.getPostTypes('artworks', this.perPage, null, [to.params.cat_slug], true, null, false, true);
          }
        }
      }
    },
    // created(){
    //   this.getPageContent();
    //   this.getPostTypes('artworks', 12, null, [this.$route.params.cat_slug], true);
    // },
    // watch:{
    //   $route (to){
    //     this.getPostTypes('artworks', 12, null, [to.params.cat_slug], true);
    //   }
    // },
  };
</script>
<style scoped>
  .post-listing-section .filters {
    margin-bottom: 50px;
  }
  .post-listing-section .pagination {
    margin-top: 50px;
  }
</style>
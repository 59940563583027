import axios from 'axios';
import { BASE_API_URL } from '@/services/api/config';

export default async function requestService(api) {
  try {
    let requestConfig = {
      method: 'GET',
      url: api,
      baseURL: BASE_API_URL,
      responseType: 'json',
      headers: {}
    };

    const response = await axios(requestConfig);
    return response;
  }
  catch(err) {
    console.error(err.response.data.message);
  }
}
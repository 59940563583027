<template>
  <svg viewBox="0 0 140 140" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_56_800)">
      <path d="M93.3041 117.214L93.0988 117.214L93.0233 53.0758L28.8819 52.9972L28.8819 52.7919L44.943 36.7309L109.302 36.7913L109.359 101.147L93.3041 117.214Z" fill="#101010"/>
      <path d="M31.9645 104.38L88.3275 48.0171L99.062 58.7516L42.699 115.115L31.9645 104.38Z" fill="#101010"/>
    </g>
    <defs>
      <clipPath id="clip0_56_800">
        <rect width="102.487" height="102.487" fill="white" transform="translate(88.7563) rotate(60)"/>
      </clipPath>
    </defs>
  </svg>
</template>
<script>
  export default {
    name: "accordionArrow",
	};
</script>
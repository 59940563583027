<template>
  <div class="404page">
    <section class="error-section">
      <div class="container">
        <div class="content">
          <h1>404</h1>
          <p>{{ $t('error404') }}</p>
        </div>
        <button-component :link="'/'">
          {{ $t('backToHome') }}
        </button-component>
      </div>
    </section>
  </div>
</template>
<script>
  import ButtonComponent from '@/components/global/button/ButtonComponent.vue';
  export default {
    name: "ErrorPage",
    components: {
      ButtonComponent
    }
  }
</script>
<style scoped>
  .error-section {
    min-height: 80vh;
    display: flex;
    align-items: center;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    padding: 150px 0;
    overflow: hidden;
    background-color: var(--black);
    color: #fff;
    text-align: center
  }
  .error-section .content {
    margin-bottom: 40px;
  }
  @media (max-width: 767px) {
    .error-section {
      padding: 80px 0;
    }
  }
</style>
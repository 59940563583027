import requestService from "@/services/request-service";
import { API_PATHS } from '@/services/api/config';

export const postTypeModule = {
  namespaced: true,
  state: {
    allPostTypes: {
      initialArtworks: [],
      artworks: [],
      initialExhibitions: [],
      exhibitions: [],
    },
    allCategories: {
      artworkCategories: [],
      phases: [],
      materials: [],
      years: [],
      exhibitionCategories: [],
    },
    initialArtworksFlag: false,
    initialExhibitionsFlag: false,
    loading: false,
  },
  mutations: {
    updateArtworks(state, data){
      if(!data) {
        state.allPostTypes.artworks = [];
        return;
      }
      state.allPostTypes.artworks = data;
    },
    updateExhibitions(state, data){
      if(!data) {
        state.allPostTypes.exhibitions = [];
        return;
      }
      state.allPostTypes.exhibitions = data;
    },
    updateInitialArtworks(state, data){
      if(!data) {
        state.allPostTypes.initialArtworks = [];
        return;
      }
      state.allPostTypes.initialArtworks = data;
    },
    updateInitialExhibitions(state, data){
      if(!data) {
        state.allPostTypes.initialExhibitions = [];
        return;
      }
      state.allPostTypes.initialExhibitions = data;
    },
    updatePhases(state, data){
      if(!data) {
        state.allCategories.phases = [];
        return;
      }
      state.allCategories.phases = data;
    },
    updateMaterials(state, data){
      if(!data) {
        state.allCategories.materials = [];
        return;
      }
      state.allCategories.materials = data;
    },
    updateYears(state, data){
      if(!data) {
        state.allCategories.years = [];
        return;
      }
      state.allCategories.years = data;
    },
    updateArtworksCategories(state, data){
      if(!data) {
        state.allCategories.artworkCategories = [];
        return;
      }
      state.allCategories.artworkCategories = data;
    },
    updateExhibitionsCategories(state, data){
      if(!data) {
        state.allCategories.exhibitionCategories = [];
        return;
      }
      state.allCategories.exhibitionCategories = data;
    },
    updateLoadingStatus(state, flag) {
      state.loading = flag;
    },
    updateinitialArtworksFlag(state, flag) {
      state.initialArtworksFlag = flag;
    },
    updateinitialExhibitionsFlag(state, flag) {
      state.initialExhibitionsFlag = flag;
    }
  },
  actions: {
    getPostType({ commit, state }, { postType = 'all', perPage = null, pageNumber = null, categories = [], filterBySlug = false, postTypeFilter = null, filterByPostType = false, getInitial = false }){ //possible values for postType: all, artworks, exhibitions
      commit('updateLoadingStatus', true);
      let catParams = categories.length > 0 ? categories.join(',') : null;

      if(postType === 'all') {
        let INITIAL_ARTWORKS_API_URL = `${API_PATHS.artworks}`;
        let ARTWORKS_API_URL = `${API_PATHS.artworks}${perPage !== null ? `&per_page=${perPage}` : ''}${pageNumber !== null ? `&page=${pageNumber}`: ''}`;
        if(getInitial || !state.initialArtworksFlag){
          requestService(INITIAL_ARTWORKS_API_URL).then((res) => {
            if(res && res.data){
              commit('updateInitialArtworks', res.data);
            }
            commit('updateinitialArtworksFlag', true);
          }).catch((err) => {
            console.error(err.response.data.message);
          });
        }
        requestService(ARTWORKS_API_URL).then((res) => {
          if(res && res.data){
            commit('updateArtworks', res.data);
          }
          commit('updateLoadingStatus', false);
        }).catch((err) => {
          console.error(err.response.data.message);
        });

        let INITIAL_EXHIBITIONS_API_URL = `${API_PATHS.exhibitions}`;
        let EXHIBITIONS_API_URL = `${API_PATHS.exhibitions}${perPage !== null ? `&per_page=${perPage}` : ''}${pageNumber !== null ? `&page=${pageNumber}`: ''}`;
        if(getInitial || !state.initialExhibitionsFlag){
          requestService(INITIAL_EXHIBITIONS_API_URL).then((res) => {
            if(res && res.data){
              commit('updateInitialExhibitions', res.data);
            }
            commit('updateinitialExhibitionsFlag', true);
          }).catch((err) => {
            console.error(err.response.data.message);
          });
        }
        requestService(EXHIBITIONS_API_URL).then((res) => {
          if(res && res.data){
            commit('updateExhibitions', res.data);
          }
          commit('updateLoadingStatus', false);
        }).catch((err) => {
          console.error(err.response.data.message);
        });

        return;
      }

      if(postType === 'artworks') {
        let INITIAL_ARTWORKS_API_URL = '';
        let ARTWORKS_API_URL = '';
        if(filterBySlug || filterByPostType || (filterBySlug && filterByPostType)) {
          INITIAL_ARTWORKS_API_URL = `${API_PATHS.artworks}${catParams !== null ? `&artwork_category_slug=${catParams}`: ''}${postTypeFilter !== null ? `&${postTypeFilter}` : ''}`;
          ARTWORKS_API_URL = `${API_PATHS.artworks}${perPage !== null ? `&per_page=${perPage}` : ''}${pageNumber !== null ? `&page=${pageNumber}`: ''}${catParams !== null ? `&artwork_category_slug=${catParams}`: ''}${postTypeFilter !== null ? `&${postTypeFilter}` : ''}`;
        } else {
          INITIAL_ARTWORKS_API_URL = `${API_PATHS.artworks}${catParams !== null ? `&artwork-categories=${catParams}`: ''}`;
          ARTWORKS_API_URL = `${API_PATHS.artworks}${perPage !== null ? `&per_page=${perPage}` : ''}${pageNumber !== null ? `&page=${pageNumber}`: ''}${catParams !== null ? `&artwork-categories=${catParams}`: ''}`;
        }
        if(getInitial || !state.initialArtworksFlag){
          requestService(INITIAL_ARTWORKS_API_URL).then((res) => {
            if(res && res.data){
              commit('updateInitialArtworks', res.data);
            }
            commit('updateinitialArtworksFlag', true);
          }).catch((err) => {
            console.error(err.response.data.message);
          });
        }
        requestService(ARTWORKS_API_URL).then((res) => {
          if(res && res.data){
            commit('updateArtworks', res.data);
          }
          commit('updateLoadingStatus', false);
        }).catch((err) => {
          console.error(err.response.data.message);
        });
        

        return;
      }

      if(postType === 'exhibitions') {
        let INITIAL_EXHIBITIONS_API_URL = '';
        let EXHIBITIONS_API_URL = '';
        if(filterBySlug || filterByPostType || (filterBySlug && filterByPostType)) {
          INITIAL_EXHIBITIONS_API_URL = `${API_PATHS.exhibitions}${catParams !== null ? `&exhibition-publication-categories=${catParams}`: ''}${postTypeFilter !== null ? `&${postTypeFilter}` : ''}`;
          EXHIBITIONS_API_URL = `${API_PATHS.exhibitions}${perPage !== null ? `&per_page=${perPage}` : ''}${pageNumber !== null ? `&page=${pageNumber}`: ''}${catParams !== null ? `&exhibition-publication-categories=${catParams}`: ''}${postTypeFilter !== null ? `&${postTypeFilter}` : ''}`;
        } else {
          INITIAL_EXHIBITIONS_API_URL = `${API_PATHS.exhibitions}${catParams !== null ? `&exhibition-publication-categories=${catParams}`: ''}`;
          EXHIBITIONS_API_URL = `${API_PATHS.exhibitions}${perPage !== null ? `&per_page=${perPage}` : ''}${pageNumber !== null ? `&page=${pageNumber}`: ''}${catParams !== null ? `&exhibition-publication-categories=${catParams}`: ''}`;
        }
        if(getInitial || !state.initialExhibitionsFlag){
          requestService(INITIAL_EXHIBITIONS_API_URL).then((res) => {
            if(res && res.data){
              commit('updateInitialExhibitions', res.data);
            }
            commit('updateinitialExhibitionsFlag', true);
          }).catch((err) => {
            console.error(err.response.data.message);
          });
        }
        requestService(EXHIBITIONS_API_URL).then((res) => {
          if(res && res.data){
            commit('updateExhibitions', res.data);
          }
          commit('updateLoadingStatus', false);
        }).catch((err) => {
          console.error(err.response.data.message);
        });

        return;
      }      
    },
    getCategories({ commit }, { postType = null, taxonomy = null}) {
      if(postType == null) {
        return;
      }
      if(postType === 'artworks'){
        if(taxonomy == null){
          let CATEGORY_API_URL = `${API_PATHS.artworksCategories}`;
          requestService(CATEGORY_API_URL).then((res) => {
            if(res && res.data){
              commit('updateArtworksCategories', res.data);
            }
            commit('updateLoadingStatus', false);
          }).catch((err) => {
            console.error(err.response.data.message);
          });
          return;
        } else if(taxonomy == 'materials') {
          let CATEGORY_API_URL = `${API_PATHS.materials}`;
          requestService(CATEGORY_API_URL).then((res) => {
            if(res && res.data){
              commit('updateMaterials', res.data);
            }
            commit('updateLoadingStatus', false);
          }).catch((err) => {
            console.error(err.response.data.message);
          });
          return;
        } else if(taxonomy == 'phases') {
          let CATEGORY_API_URL = `${API_PATHS.phases}`;
          requestService(CATEGORY_API_URL).then((res) => {
            if(res && res.data){
              commit('updatePhases', res.data);
            }
            commit('updateLoadingStatus', false);
          }).catch((err) => {
            console.error(err.response.data.message);
          });
          return;
        } else if(taxonomy == 'years') {
          let CATEGORY_API_URL = `${API_PATHS.years}`;
          requestService(CATEGORY_API_URL).then((res) => {
            if(res && res.data){
              commit('updateYears', res.data);
            }
            commit('updateLoadingStatus', false);
          }).catch((err) => {
            console.error(err.response.data.message);
          });
          return;
        }

        return;
      }
      if(postType === 'exhibitions'){
        let CATEGORY_API_URL = `${API_PATHS.exhibitionsCategories}`;
        requestService(CATEGORY_API_URL).then((res) => {
          if(res && res.data){
            commit('updateExhibitionsCategories', res.data);
          }
          commit('updateLoadingStatus', false);
        }).catch((err) => {
          console.error(err.response.data.message);
        });

        return;
      }

      return false;
    },
  },
  getters: {
    getAllPostTypes(state){
      return state.allPostTypes || {};
    },
    getArtworks(state){
      return state.allPostTypes.artworks || {};
    },
    getInitialNumberOfArtworks(state){
      return state.allPostTypes.initialArtworks.length || 0;
    },
    getArtworksCategories(state){
      return state.allCategories.artworkCategories || {};
    },
    getPhases(state){
      return state.allCategories.phases || {};
    },
    getMaterials(state){
      return state.allCategories.materials || {};
    },
    getYears(state){
      return state.allCategories.years || {};
    },
    getExhibitions(state){
      return state.allPostTypes.exhibitions || {};
    },
    getInitialNumberOfExhibitions(state){
      return state.allPostTypes.initialExhibitions.length || 0;
    },
    getExhibitionsCategories(state){
      return state.allCategories.exhibitionCategories || {};
    },
    getLoading(state){
      return state.loading;
    }
  }
}
<template>
  <section :class="currentRoute !== 'home' ? 'hero-section small' : 'hero-section'" :style="image ? `background-image: url(${image})` : null">
    <div class="container">
      <div class="hero-content">
        <h1 v-if="title">{{ title }}</h1>
        <div class="hero-text" v-if="text">
          <p>{{ text }}</p>
        </div>
        <button-component v-if="button" :link="'contact'">
          {{ $t('routes.contact') }}
        </button-component>
      </div>
    </div>
  </section>
</template>

<script>
	import ButtonComponent from './button/ButtonComponent.vue';

  export default {
    data() {
      return {
        currentRoute: this.$route.name,
      };
    },
    name: "HeroSection",
		props: {
			title: {
				type: String,
				default: ''
			},
			text: {
				type: String,
				default: ''
			},
			image: {
				type: [Object, String],
				default: () => {}
			},
			button: {
				type: Boolean,
				default: false
			}
		},
		components: {
			ButtonComponent,
    },
	};
</script>

<style scoped>
  .hero-section {
    min-height: 100vh;
    display: flex;
    align-items: center;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    padding: 150px 0;
    overflow: hidden;
  }
  .hero-section.small {
    min-height: 75vh;
  }
  .hero-section:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(19, 19, 19, 0.3);
  }
  .hero-section .hero-content {
    text-align: center;
    position: relative;
    z-index: 1;
    color: #fff;
  }
  .hero-section .hero-content h1 {
    text-transform: uppercase
  }
  .hero-section .hero-content .hero-text {
    max-width: 650px;
    margin: 0 auto;
  }
  .hero-section .hero-content .hero-text:not(:last-child) {
    margin-bottom: 40px;
  }
  @media (max-width: 767px) {
    .hero-section {
      padding: 80px 0;
    }
  }
</style>
import "./plugins/axios";
import { createApp, watch } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "./plugins/axios";
import i18n from "./plugins/i18n";

i18n.setup();



createApp(App)
  .use(router)
  .use(store)
  .use(axios)
  .use(i18n.vueI18n)
  .mount("#app");

watch(
  store.state,
  (state) => {
    localStorage.setItem("currentLanguage", state.languageModule.defaultLocale);
  },
  { deep: true }
);

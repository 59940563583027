<template>
  <button
    :class="['filter-btn', { 'active' : active }]"
    @click="click(id)">
    <h5>{{ text }}</h5>
  </button>
</template>

<script>

  export default {
    name: "FilterButton",
    props: {
      id: {
        type: [Number, String, Object],
        default: null,
      },
      text: {
        type: String,
        required: true,
      },
      active: {
        type: Boolean,
        default: false,
      },
    },
    methods: {
      click(id){
        this.$emit("changeActive", id);
      }
    }
  }
</script>
<style scoped>
  .filter-btn {
    padding: 14px 22px;
    border: 2px solid var(--black);
    border-radius: 100vw;
    text-transform: uppercase;
    color: var(--black);
    transition: all .3s ease-in-out;
  }
  .filter-btn:hover,
  .filter-btn.active {
    background-color: var(--black);
    color: #fff;
  }
  .filter-btn h5 {
    pointer-events: none;
  }
</style>
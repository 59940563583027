<template>
  <div class="language-switcher">
    <custom-select 
      :options="locales"
      :default="defaultLocale"
      class="select"
      @change="onLocaleChange($event)"/>
  </div>
</template>

<script>
  import { supportedLocales } from '@/plugins/i18n';
  import CustomSelect from '../CustomSelect.vue';

  export default {
    name: "LanguageSwitcher",
		components: {
      CustomSelect
    },
    methods: {
      
      // Called when the user selects a new locale
      // from the dropdown
      onLocaleChange(event) {
        let newLocale = '';
        if(typeof event == 'object'){
          newLocale = event[0];
        } else {
          newLocale = event;
        }
        // If the selected locale is the same as the
        // active one, do nothing
        if (newLocale === this.$i18n.locale) {
          return
        }
        
        let newRoute = '';
        if(this.$route.fullPath === `/${this.$i18n.locale}`){
          newRoute = this.$route.fullPath.replace(`${this.$i18n.locale}`, `${newLocale}`);
        } else {
          newRoute = this.$route.fullPath.replace(`/${this.$i18n.locale}/`, `/${newLocale}/`)
        }
        
        // Navigate to the localized root route for
        // the chosen locale
        this.$store.dispatch('languageModule/updateLocale', newLocale);
        this.$router.push(newRoute).then(() => { this.$router.go(0) });
      },
    },
    computed: {
      // Transfrom our supportedLocales object to 
      // an array of [{ code: 'en-US', name: 'English' }, ...]
      locales() {
        return supportedLocales;
      },
      defaultLocale() {
        return this.$store.getters['languageModule/getDefaultLocale']
      }
    },
	};
</script>
<style scoped>
  .language-switcher .custom-select .selected {
    padding: 6px 0;
  }
  .language-switcher >>> .custom-select {
    position: relative;
    width: 100%;
    text-align: left;
    outline: none;
    padding-right: 24px;
  }

  .language-switcher >>> .custom-select .selected {
    color: #fff;
    cursor: pointer;
    user-select: none;
  }

  .language-switcher >>> .custom-select .selected:after {
    position: absolute;
    content: "";
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    width: 20px;
    height: 20px;
    background-image: url('../../../assets/icons/dropdown-arrow.svg');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }

  .language-switcher >>> .custom-select .items {
    color: var(--black);
    overflow: hidden;
    position: absolute;
    background-color: #fff;
    top: calc(100% + 6px);
    left: 0;
    z-index: 1;
    padding: 8px;
  }
  

  .language-switcher >>> .custom-select .items div {
    color: var(--black);
    cursor: pointer;
    user-select: none;
    font-weight: 600;
  }

  .language-switcher >>> .custom-select .items div:hover {
    color: var(--gold);
  }
</style>
import { createRouter, createWebHistory } from "vue-router";
import i18n, { defaultLocale } from "../plugins/i18n";
import PageComponent from "../views/PageComponent.vue";
import store from "@/store";

const routes = [
  {
    path: "/",
    redirect: `/${defaultLocale}`,
  },
  {
    path: "/:locale",
    beforeEnter: (to, from, next) => {
      const paramLocale = to.params.locale;
      if(paramLocale !== 'en' && paramLocale !== 'mk') {
        return next(`/${defaultLocale}/${paramLocale}`);
      }
      if(paramLocale === 'en' || paramLocale === 'mk') {
        store.dispatch('languageModule/updateLocale', paramLocale);
      }
      return next();
    },
    children: [
      {
        // The empty path specifies the default
        // child route component
        path: "",
        name: "home",
        component: PageComponent,
      },
      {
        // Using the relative 'biography' not the absolute
        // '/biography' allows us to include the :locale
        // param from the parent.
        path: "biography",
        name: "biography",
        // route level code-splitting
        // this generates a separate chunk (biography.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "biography" */ "../views/PageComponent.vue"),
      },
      {
        path: "artworks",
        name: "artworks",
        component: () => import(/* webpackChunkName: "artwork" */ "../views/PageComponent.vue"),
        children: [
          {
            path: ":cat_slug",
            name: "artworkCategory",
            component: () => import(/* webpackChunkName: "artwork" */ "../views/PageComponent.vue"),
            children: [
              {
                path: ":slug",
                name: "singlePostAr",
                component: () => import(/* webpackChunkName: "artwork" */ "../views/PageComponent.vue"),
              }
            ]
          }
        ]
      },
      {
        path: "exhibitions-and-publications",
        name: "exhibitionsPublications",
        component: () => import(/* webpackChunkName: "biography" */ "../views/PageComponent.vue"),
        children: [
          {
            path: ":cat_slug",
            name: "exhibitionsPublications",
            component: () => import(/* webpackChunkName: "artwork" */ "../views/PageComponent.vue"),
            children: [
              {
                path: ":slug",
                name: "singlePostEx",
                component: () => import(/* webpackChunkName: "artwork" */ "../views/PageComponent.vue"),
              }
            ]
          }
        ]
      },
      {
        path: "contact",
        name: "contact",
        component: () => import(/* webpackChunkName: "biography" */ "../views/PageComponent.vue"),
      },
      {
        path: "404",
        name: "404",
        component: () => import(/* webpackChunkName: "biography" */ "../views/PageComponent.vue"),
      },
      { path: ':catchAll(.*)', redirect:'404' }
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: 'active',
  linkExactActiveClass: 'active-exact'
});

router.beforeEach((to, from) => {
  const newLocale = to.params.locale;
  const prevLocale = from.params.locale;
  // If the locale hasn't changed, do nothing
  if (newLocale === prevLocale) {
    return;
  }
  i18n.setLocale(newLocale);
});

export default router;

<template>
  <div class="social">
    <div class="social-item">
      <a href="#">
        <fbSvg/>
      </a>
    </div>
    <div class="social-item">
      <a href="#">
        <xSvg/>
      </a>
    </div>
  </div>
</template>

<script>
	import fbSvg from './fbSvg.vue';
  import xSvg from './xSvg.vue';

  export default {
    name: "SocialMedia",
		components: {
			fbSvg,
      xSvg
    },
	};
</script>
<style scoped>
  .social {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .social .social-item {
    max-height: 18px;
    height: 100%;
  }
  svg {
    height: 18px;
    width: 18px;
  }
</style>
<style>
  svg path {
    transition: all .3s ease-in-out;
  }
  .social .social-item:hover svg path {
    fill: var(--gold);
  }
</style>
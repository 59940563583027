<template>
  <footer>
    <div class="footer-top">
      <div class="container">
        <div class="wrap">
          <div class="logo">
            <localized-link :to="'/'">
              <img src="../../../assets/logo.svg" alt="">
            </localized-link>
          </div>
          <nav-component
            :routes="routes" />
          <social-media/>
        </div>
      </div>
    </div>
    <div class="footer-bottom">
      <div class="container">
        <span>Created by <a href="https://peligital.com" target="_blank">Peligital</a>. {{ getYear }} © All rights reserved.</span>
      </div>
    </div>
  </footer>
</template>

<script>
	import NavComponent from '../NavComponent.vue';
  import SocialMedia from '../social-media/SocialMedia.vue';
  import LocalizedLink from '@/components/l10n/LocalizedLink.vue';

  export default {
    name: "HeaderComponent",
		components: {
			NavComponent,
      SocialMedia,
      LocalizedLink
    },
    props: {
			routes: {
				type: Array,
				default: () => {}
			}
		},
    computed: {
      getYear(){
        const nowDate = new Date();
        return nowDate.getFullYear();
      }
    }
	};
</script>
<style scoped>
  .footer-top {
    background-color: var(--black);
    padding: 80px 0;
  }
  .footer-top .logo {
    max-width: 180px;
    width: 100%;
  }
  .footer-bottom {
    padding: 10px 0;
    text-align: center;
  }
  @media (max-width: 991px) {
    footer .wrap {
      justify-content: center;
      flex-direction: column;
      gap: 40px;
    }
  }
</style>

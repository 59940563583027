<template>
  <div class="filters">
    <template v-if="!data.filterType && data.filterType !== 'select'">
      <filter-button
        v-if="includeAll"
        :id="null"
        :text="$t('all')"
        :active="includeAll && selectedFilterId === null"
      @changeActive="changeActive(null)"/>
      <filter-button
        v-for="(btn, i) in data"
        :key="btn.slug + btn.id"
        :id="btn.id"
        :text="btn.name"
        :active="selectedFilterId === btn.id || selectedFilterId === btn.slug || (i === 0 && !includeAll)"
        @changeActive="changeActive(filterBySlug ? btn.slug : btn.id)"/>
    </template>
    <template v-else>
      <filter-button
        v-if="includeAll"
        :id="null"
        :text="$t('all')"
        :active="includeAll && selectedFilterId === null"
        @changeActive="changeActive(null)"/>
      <custom-select 
        v-for="(btn, i) in getSelectData"
        :key="`${btn.slug}-${i}`"
        :options="btn.data"
        :default="$route.query[btn.slug] || null"
        :resetFilters="getResetFilters"
        class="select"
        @change="onSelect($event, btn.slug)"/>
    </template>
  </div>
</template>

<script>
  import FilterButton from './FilterButton.vue';
  import CustomSelect from '../CustomSelect.vue';

  export default {
    data() {
      return {
        selectedFilterId: null,
        resetFilters: false,
      }
    },
    name: "FilterButtons",
    components: {
      FilterButton,
      CustomSelect
    },
    props: {
      data: {
        type: [Object, Array],
        required: true,
      },
      postType: {
        type: String,
        required: true,
      },
      includeAll: {
        type: Boolean,
        default: true,
      },
      filterBySlug: {
        type: Boolean,
        default: false,
      }
    },
    computed: {
      getSelectData() {
        if(!this.data.filterType && this.data.filterType !== 'select') {
          return;
        }
        let newObject = Object.assign({}, this.data);
        delete newObject['filterType'];
        return newObject;
      },
      getResetFilters(){
        return this.resetFilters;
      },
    },
    methods: {
      changeActive(btnId){
        if(this.selectedFilterId === btnId){
          return;
        }
        this.selectedFilterId = btnId;
        this.$emit('filterRequest', {
          postType: this.postType,
          btnId: btnId
        });
        if(this.selectedFilterId == null){
          if(this.$route.query['id']){
            this.$router.push({query: {id:this.$route.query['id']}});
          } else {
            this.$router.push({query: null});
          }
          
        }
        this.resetFilters = true;
      },
      onSelect(event, slug) {
        this.$emit('selectRequest', {
          postType: this.postType,
          slug: slug,
          id: event[0]
        });
        let params = {};
        params[slug] = event[0];
        let queryParam = Object.assign({}, this.$route.query, params);
        delete queryParam['page'];
        if(event[0] !== '0') {
          this.$router.push({query: queryParam});
          this.selectedFilterId = undefined;
        } else {
          let removeQueryParam = Object.assign({}, this.$route.query, {});
          delete removeQueryParam[slug];
          delete removeQueryParam['page'];
          this.$router.push({query: removeQueryParam});
          if(Object.keys(removeQueryParam).length === 0){
            this.selectedFilterId = null;
          }
        }
        this.resetFilters = false;
      },
    },
    created() {
      if(Object.keys(this.$route.query).length > 0) {
        this.selectedFilterId = undefined;
      }
    }
  }
</script>
<style scoped>
  .filters {
    display: flex;
    align-items: center;
    gap: 5px;
    flex-wrap: wrap;
  }
  .filters >>> .custom-select {
    position: relative;
  }
  .filters >>> .custom-select .selected {
    position: relative;
    padding: 14px 22px;
    padding-right: 50px;
    border: 2px solid var(--black);
    border-radius: 100vw;
    text-transform: uppercase;
    color: var(--black);
    transition: all .3s ease-in-out;
    font-weight: 700;
    font-size: 18px;
    cursor: pointer;
  }
  .filters >>> .custom-select .selected:after {
    content: '';
    position: absolute;
    right: 20px;
    top: calc(50% - 3px);
    border: solid var(--black);
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(45deg) translateY(-50%);
    -webkit-transform: rotate(45deg) translateY(-50%);
  }
  .filters >>> .custom-select .items {
    position: absolute;
    top: calc(100% + 4px);
    z-index: 1;
    left: 0;
    padding: 22px;
    min-width: 100%;
    background: #fff;
    border: 2px solid var(--black);
    border-radius: 20px;
  }
  .filters >>> .custom-select .items > div {
    color: var(--black);
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    font-weight: 600;
    text-transform: uppercase;
  }
  .filters >>> .custom-select .items > div:not(:last-child) {
    margin-bottom: 5px;
  }
  .filters >>> .custom-select .items > div:hover {
    color: var(--gold);
  }
  @media (max-width: 767px) {
    .filters {
      gap: 10px;
    }
    .filters >>> .custom-select .selected {
      font-size: 16px;
    }
  }
</style>
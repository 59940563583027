import { createStore } from "vuex";
import { languageModule } from "./modules/language-module";
import { homePageModule } from "./modules/home-page-module";
import { biographyPageModule } from "./modules/biography-page-module";
import { artworksPageModule } from "./modules/artworks-page-module";
import { exhibitionsPageModule } from "./modules/exhibitions-page-module";
import { postTypeModule } from "./modules/post-types-module";
import { singlePostModule } from "./modules/single-post-module";
import { contactPageModule } from "./modules/contact-page-module";

export default createStore({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    languageModule,
    homePageModule,
    biographyPageModule,
    artworksPageModule,
    exhibitionsPageModule,
    postTypeModule,
    singlePostModule,
    contactPageModule
  },
});

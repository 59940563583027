<template>
  <div class="pagination" v-show="numberOfPages > 1">
    <div class="pag-arrow prev"
      :class="firstPage ? 'disabled' : ''"
      @click="prevPage()">
      <span class="arr"></span>
      <b>Previous</b>
    </div>
    <div class="pag-numbers">
      <span 
        v-for="page in numberOfPages" 
        :key="`page-${page}`"
        :class="page === activePage ? 'active' : ''"
        @click="changePage(page)">{{ page }}</span>
    </div>
    <div class="pag-arrow next"
      :class="lastPage ? 'disabled' : ''"
      @click="nextPage()">
      <b>Next</b>
      <span class="arr"></span>
    </div>
  </div>
</template>

<script>
  export default {
    name: "PaginationComponent",
		props: {
			totalNumberItems: {
				type: Number,
				default: 0
			},
      perPage: {
        type: Number,
				default: 12
      }
		},
    computed: {
      numberOfPages(){
        if(this.totalNumberItems <= this.perPage) {
          return 1;
        }
        return Math.ceil(this.totalNumberItems / this.perPage);
      },
      activePage(){
        if(this.$route.query && Object.keys(this.$route.query).length > 0 && this.$route.query['page']) {
          return +this.$route.query['page'];
        }
        return 1;
      },
      firstPage(){
        return this.activePage === 1;
      },
      lastPage(){
        return this.activePage === this.numberOfPages;
      }
    },
    methods: {
      changePage(page){
        if(this.activePage === page) {
          return;
        }
        if(page === 1){
          let removeQueryParam = Object.assign({}, this.$route.query, {});
          delete removeQueryParam['page'];
          this.$router.push({query: removeQueryParam});
          return;
        }
        let queryParam = Object.assign({}, this.$route.query, {'page': page});
        this.$router.push({query: queryParam});
      },
      nextPage(){
        if(this.lastPage){
          return;
        }
        let nextPage = typeof this.$route.query['page'] == 'undefined' ? 2 : +this.$route.query['page'] + 1;
        let queryParam = Object.assign({}, this.$route.query, {'page': nextPage});
        this.$router.push({query: queryParam});
      },
      prevPage(){
        if(this.firstPage){
          return;
        }
        let prevPage = +this.$route.query['page'] - 1;
        let queryParam = Object.assign({}, this.$route.query, {'page': prevPage});
        if(+this.$route.query['page'] === 2){
          delete queryParam['page'];
        }
        this.$router.push({query: queryParam});
      }
    },
	};
</script>

<style scoped>
  .pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }
  .pagination .pag-numbers {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .pagination .pag-numbers span {
    width: 48px;
    height: 48px;
    font-weight: 900;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--black);
    transition: all .3s ease-in-out;
    background: transparent;
    border-radius: 50%;
    cursor: pointer;
  }
  .pagination .pag-numbers span:hover,
  .pagination .pag-numbers span.active {
    color: #fff;
    background: var(--black);
  }
  .pagination .pag-arrow {
    display: inline-flex;
    width: 30px;
    height: 30px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all .3s ease-in-out;
    border-radius: 50%;
  }
  .pagination .pag-arrow:hover {
    background: var(--black);
  }
  .pagination .pag-arrow.disabled,
  .pagination .pag-arrow.disabled:hover {
    background: transparent;
    cursor: not-allowed;
  }
  .pagination .pag-arrow span {
    border: solid var(--black);
    display: inline-block;
    padding: 3px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    transform-origin: center;
    transition: all .3s ease-in-out;
    position: relative;
  }
  .pagination .pag-arrow:hover span {
    border: solid #fff;
  }
  .pagination .pag-arrow.prev span {
    border-width: 0 0 3px 3px;
    left: 2px;
  }
  .pagination .pag-arrow.next span {
    border-width: 3px 3px 0 0;
    right: 2px;
  }
  .pagination .pag-arrow.disabled span,
  .pagination .pag-arrow.disabled:hover span {
    border-color: #e7e7e7;
  }
  .pagination .pag-arrow b {
    display: none;
  }
  .pagination .pag-arrow.disabled b {
    color: #e7e7e7;
  }
  @media (max-width: 991px) {
    .pagination {
      gap: 20px;
      justify-content: space-between;
      font-size: 22px;
    }
    .pagination .pag-numbers {
      display: none;
    }
    .pagination .pag-arrow {
      width: auto;
      height: auto;
    }
    .pagination .pag-arrow b {
      display: block;
    }
    .pagination .pag-arrow.prev b {
      margin-left: 10px;
    }
    .pagination .pag-arrow.next b {
      margin-right: 10px;
    }
  }
</style>
<template>
  <component 
    :is="html == 'link' ? 'localized-link' : 'button'"
    :to="html == 'link' ? link : null"
    :type="html == 'submit' ? 'submit' : null"
    :class="[
      'btn',
      { 
        'btn-primary' : type === 'primary',
        'btn-text-arrow' : type === 'text-arrow',
        'btn-arrow' : type === 'arrow',
      }
    ]">
    <span v-if="type !== 'arrow'">
      <slot></slot>
    </span>
    <span class="icon">
      <arrow-svg/>
    </span>
  </component>
</template>

<script>
  import LocalizedLink from '../../l10n/LocalizedLink.vue';
  import arrowSvg from './arrowSvg.vue';

  export default {
    name: "ButtonComponent",
		props: {
			html: {
				type: String,
				default: 'link'
			},
      type: {
        type: String,
				default: 'primary' // possible options: primary, text-arrow, arrow
      },
      link: {
        type: String,
        default: ''
      }
		},
    components: {
			arrowSvg,
      LocalizedLink
    },
	};
</script>
<style>
	.btn {
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    outline: none;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    font-weight: 700;
    border: none;
    position: relative;
  }
  .btn span {
    position: relative;
    z-index: 2;
  }
  .btn .icon {
    transform: rotate(-45deg);
    transform-origin: center;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
  .btn .icon svg {
    width: 20px;
    position: relative;
    z-index: 2;
  }
  .btn:hover .icon {
    transform: rotate(0);
  }
  /* PRIMARY */
  .btn-primary {
    gap: 12px;
    padding: 18px 24px;
    background-size: 100%;
    border-radius: 100vw;
    color: #fff!important;
    overflow: hidden;
    background-color: #fff;
  }
  .btn-primary:hover {
    color: #D2AC47!important;
  }
  .btn-primary:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: var(--gold-gradient);
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
  .btn-primary:hover::before {
    opacity: 0;
  }
  .btn-primary .icon svg stop {
    stop-color: #fff;
    transition: all 0.3s ease-in-out;
  }
  .btn-primary:hover .icon svg linearGradient stop:first-child {
    stop-color: #F3E17B;
  }
  .btn-primary:hover .icon svg linearGradient stop:nth-child(2) {
    stop-color: #D2AC47;
  }
  /* TEXT-ARROW */
  .btn-text-arrow {
    gap: 16px;
    color: #fff!important;
  }
  .btn-text-arrow.black {
    color: var(--black)!important;
  }
  .btn-text-arrow .icon {
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    border-radius: 50%;
    background-color: #fff;
  }
  .btn-text-arrow.black .icon {
    background-color: var(--gold);
  }
  .btn-text-arrow.black:hover .icon {
    background-color: var(--black);
  }
  .btn-text-arrow.black:hover .icon {
    background-color: var(--black);
  }
  .btn-text-arrow .icon:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: var(--gold-gradient);
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
  .btn-text-arrow:hover .icon:before {
    opacity: 0;
  }
  .btn-text-arrow .icon svg {
    position: relative;
    z-index: 2;
  }
  .btn-text-arrow .icon svg path {
    fill: #fff;
    transition: all 0.3s ease-in-out;
  }
  .btn-text-arrow:not(.black):hover .icon svg path {
    fill: var(--gold);
  }
  /* .btn-text-arrow:not(.black):hover .icon svg linearGradient stop:nth-child(2) {
    stop-color: #D2AC47;
  } */
  /* ARROW */
  .btn-arrow .icon {
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    border-radius: 50%;
    background-color: var(--black);
  }
  .btn-arrow:hover .icon {
    background-color: var(--gold);
  }
  .btn-arrow .icon svg path {
    fill: #fff;
    transition: all 0.3s ease-in-out;
  }
  .btn-arrow .icon:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: var(--gold-gradient);
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    opacity: 0;
  }
  .btn-arrow:hover .icon:before {
    opacity: 1;
  }

  @media (max-width: 1199px) {
    .btn {
      font-size: 16px;
    }
    .btn .icon svg {
      width: 16px;
    }
    .btn-arrow .icon {
      width: 40px;
      height: 40px;
    }
    .btn-text-arrow .icon {
      width: 40px;
      height: 40px;
    }
  }
</style>
